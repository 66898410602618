/* tslint:disable */

/**
 * field to filter for transaction search:
 *   * ALL : All fields
 *   * BADGE : transaction badge uid
 */
type TransactionSearchFilter =
  'ALL' |
  'BADGE';
module TransactionSearchFilter {
  export const ALL: TransactionSearchFilter = 'ALL';
  export const BADGE: TransactionSearchFilter = 'BADGE';
  export function values(): TransactionSearchFilter[] {
    return [
      ALL,
      BADGE
    ];
  }
}

export { TransactionSearchFilter }