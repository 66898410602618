/* tslint:disable */

/**
 * The station's access type:
 *   * PRIVATE:
 *   * PUBLIC:
 *   * PUBLIC_WITH_RESTRICTIONS:
 *   * RESIDENTIAL:
 *   * SHOPPING_CENTER:
 */
type AccessType =
  'PRIVATE' |
  'PUBLIC' |
  'PUBLIC_WITH_RESTRICTIONS' |
  'RESIDENTIAL' |
  'SHOPPING_CENTER';
module AccessType {
  export const PRIVATE: AccessType = 'PRIVATE';
  export const PUBLIC: AccessType = 'PUBLIC';
  export const PUBLIC_WITH_RESTRICTIONS: AccessType = 'PUBLIC_WITH_RESTRICTIONS';
  export const RESIDENTIAL: AccessType = 'RESIDENTIAL';
  export const SHOPPING_CENTER: AccessType = 'SHOPPING_CENTER';
  export function values(): AccessType[] {
    return [
      PRIVATE,
      PUBLIC,
      PUBLIC_WITH_RESTRICTIONS,
      RESIDENTIAL,
      SHOPPING_CENTER
    ];
  }
}

export { AccessType }