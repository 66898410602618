/* tslint:disable */

/**
 * field to filter for stations search:
 *   * ALL : All fields
 *   * NAME : the station's name
 */
type NetworkSearchFilter =
  'ALL' |
  'NAME';
module NetworkSearchFilter {
  export const ALL: NetworkSearchFilter = 'ALL';
  export const NAME: NetworkSearchFilter = 'NAME';
  export function values(): NetworkSearchFilter[] {
    return [
      ALL,
      NAME
    ];
  }
}

export { NetworkSearchFilter }