import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../auth.service';
import { LoggedInService } from '../../services/loggedIn.service';
import { UserSessionService } from '../../services/session/user-session.service';
import { AppGuardType } from '../../enum/AppGuardType';
import { environment } from 'src/environments/environment';
import { OktaAuthGuard } from '@okta/okta-angular';
import { CustomOktaAuthGuard } from './custom-okta-auth-guard';
import { User } from 'src/app/shared/models/user/user';
import { FullyLoggedInStateType } from '../../enum/FullyLoggedInStateType';


@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {

  state: RouterStateSnapshot;
  route: ActivatedRouteSnapshot;
  isRefreshPage = true;

  constructor(public router: Router,
              public authService: AuthService,
              public loggedInService: LoggedInService,
              public userSessionService: UserSessionService,
              public oktaAuthGuard: OktaAuthGuard,
              public customOktaAuthGuard: CustomOktaAuthGuard) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.state = state;
      this.route = route;

      if (environment.authAppGuard === AppGuardType.AUTHENTICATED_BY_OKTA_THEN_LOGGEDIN) {
        return this.authenticatedByOktaThenLoggedIn();
      } else if (environment.authAppGuard === AppGuardType.NOT_AUTHENTICATED_JUST_LOGGEDIN) {
        return this.notAuthenticatedJustLoggedIn();
      } else if (environment.authAppGuard === AppGuardType.AUTHENTICATED_BY_CUSTOM_OKTA_THEN_LOGGEDIN) {
        return this.authenticatedByCustomOktaThenLoggedIn();
      } else {
        console.error('unknown: environment.authAppGuard in AppGuard');
      }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

  /**
   * Check if user is loggedIn if he is authenticated
   */
  private authenticatedByOktaThenLoggedIn(): Promise<boolean> {
    return this.oktaAuthGuard.canActivate(this.route, this.state).then(
      (canActivate) => {
        if (canActivate) {
          return this.defaultChecking(canActivate);
        } else {
          this.userSessionService.cleanSession();
          return false;
        }
      },
      (error) => {
        console.log('error on authenticatedByOktaThenLoggedIn(): ', error);
        return false;
      }
    );
  }

  /**
   * Check if user is loggedIn if he is authenticated
   */
  async defaultChecking(isAuthenticated: boolean) {
    if (isAuthenticated) {
      const isLoggedIn = await this.loggedInService.isLoggedIn();
      if (isLoggedIn) {
        await this.refreshUser();

        if (this.userHasSufficientRights()) {
          if (environment.authAppGuard === AppGuardType.NOT_AUTHENTICATED_JUST_LOGGEDIN) {
            this.loggedInService.fullyLoggedInState$.next(FullyLoggedInStateType.SUCCESS);
          }
          return true;
        } else {
          // this.router.navigate(['error-page']);
          return false;
        }
      } else {
        const redirectTo = (this.state.url) ? this.state.url : environment.authPathRedirectAfterLogin;
        // set route in localstorage
        this.userSessionService.setRedirectTo(redirectTo);
        // redirect
        this.router.navigate([environment.authPathLoggedInCallback]);
        return false;
      }
    }
  }

  private async notAuthenticatedJustLoggedIn() {
    return await this.defaultChecking(true);
  }

  /**
   * Check if user is loggedIn if he is authenticated
   */
  private authenticatedByCustomOktaThenLoggedIn(): Promise<boolean> {

    return this.customOktaAuthGuard.canActivate(this.route, this.state).then(
      (canActivate) => {
        if (canActivate) {
          return this.defaultChecking(canActivate);
        } else {
          this.userSessionService.cleanSession();
          return false;
        }
      },
      (error) => {
        console.log('error on authenticatedByOktaThenLoggedIn(): ', error);
        return false;
      }
    );
  }

  /**
   * Refresh User only on refresh page
   */
  async refreshUser() {
    if (this.isRefreshPage) {
      this.isRefreshPage = false;

      // check if user has changed in database
      await this.loggedInService.refreshUser();
    }
  }

  private userHasSufficientRights(): boolean {
    const currentUser: User = this.userSessionService.getUser();
    if (this.route.data.roles) {
      const roles = this.route.data.roles as Array<string>;
      const intersec = currentUser.roles.filter(roleUser => roles.includes(roleUser));

      if (intersec.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
