import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { OktaCustomCallBackComponent } from '../components/okta-custom-call-back/okta-custom-call-back.component';


const routes: Routes = [
  { path: 'implicit/callback', component: OktaCallbackComponent },
  { path: 'implicit/custom-callback', component: OktaCustomCallBackComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OktaRoutingModule { }
