import { StationSummary } from "src/swagger-gen/web/models";

export class UserAllowedStationModel {

    uid: string;
    name: string;
    code: string;

    constructor(res: StationSummary) {
        this.uid = res.uid;
        this.name = res.name || null;
        this.code = res.code || null;
    }

    public static getListFromApi(res: StationSummary[]): UserAllowedStationModel[] {
        const list: UserAllowedStationModel[] = [];
        if (res) {
            res.forEach(r => {
                list.push(new UserAllowedStationModel(r));
            });
        }

        return list;
    }
}