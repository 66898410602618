import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ErrorMessageConfig } from '../tinea-components/error-messages/models/ErrorMessageConfig';
import { ErrorMessageDirection } from '../tinea-components/error-messages/enums/ErrorMessageDirection';
import { UiIcon } from '../tinea-components/table/models/input/ui-icon';
import { CoreEnum } from '../enums/core.enum';
import { UiLoadingModel } from '../tinea-components/loading/models/UiLoadingModel';
import { LoadingTypeEnum } from '../tinea-components/loading/enum/loadingType.enum';
import { DisplayText } from '../models/events/displayText';


@Injectable({
  providedIn: 'root'
})
export class BaseService {

  readonly DURATION_ERROR_MSG = 5000;
  readonly DURATION_ERROR_MSG_ANIMATION = 1000;

  constructor() { }

  protected pagination(offset: number, limit: number, defaultLimit: string): string {
    let offsetString;
    let limitString;
    offset ? offsetString = 'offset=' + offset : offsetString = 'offset=0';
    limit ? limitString = '&limit=' + limit : limitString = '&limit=' + defaultLimit;
    const paging = offsetString + limitString;
    return paging;
  }

  protected httpHeaders(): HttpHeaders {
    return this.httpHeadersDefault().set('Accept', 'application/json');
  }

  protected httpHeadersForImage(): HttpHeaders {
    return this.httpHeadersDefault().set('Accept', 'image/jpeg, image/png, image/svg+xml, application/json');
  }

  protected httpHeadersForCsv(): HttpHeaders {
    return this.httpHeadersDefault().set('Accept', 'text/csv');
  }

  protected httpHeadersForZip(): HttpHeaders {
    return this.httpHeadersDefault().set('Accept', 'application/zip');
  }

  protected httpHeadersWithCharset(): HttpHeaders {
    return this.httpHeadersDefault().set('Accept', 'application/json')
                                            .set('Content-Type', 'application/json;charset=UTF-8');
  }

  protected httpHeadersForText(): HttpHeaders {
    return this.httpHeadersDefault().set('Accept', 'text/plain')
                                            .set('Content-Type', 'text/plain');
  }

  private httpHeadersDefault(): HttpHeaders {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return headers;
  }

  public getDefaultErrorMessageConfig(): ErrorMessageConfig {
    const model = new ErrorMessageConfig('static', ErrorMessageDirection.RIGHT_LEFT);
    model.style.transition = 'all 1s';
    return model;
  }

  protected handleServerError(badResponse: HttpErrorResponse) {
    // DEBUG logs:
    // this.logger.log('Error : ' , badResponse);
    if (!!badResponse.error && badResponse.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', badResponse.error.message);
    } else if (!!badResponse.error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${badResponse.status}, ` +
        `body was: ${badResponse.error.error ? badResponse.error.error : badResponse.message}`);
    }
    return throwError(badResponse);
  }

  public generateDefaultSpinner(type: LoadingTypeEnum = null, cssClassText: string = '', cssClassIcon: string = '', displayText: DisplayText = null): UiLoadingModel {
    const spinner = new UiLoadingModel(type);
    spinner.isLoading = false;
    spinner.icon = new UiIcon();
    spinner.icon.value = CoreEnum.IMG_PATH_SPINNER;
    spinner.text = displayText;
    spinner.cssClassText = cssClassText;
    spinner.icon.cssClass = cssClassIcon + ' ' + CoreEnum.CLASS_SPINNER_FOR_ROTATE;
    spinner.type = type;

    return spinner;
  }
}
