/* tslint:disable */

/**
 * The role types:
 *   * TECHNICAL : role for technical users
 *   * MANAGEMENT : role for management users
 */
type RoleType =
  'TECHNICAL' |
  'MANAGEMENT';
module RoleType {
  export const TECHNICAL: RoleType = 'TECHNICAL';
  export const MANAGEMENT: RoleType = 'MANAGEMENT';
  export function values(): RoleType[] {
    return [
      TECHNICAL,
      MANAGEMENT
    ];
  }
}

export { RoleType }