/* tslint:disable */

/**
 * level of escalation:
 *   * NONE
 *   * LEVEL1
 *   * LEVEL2
 *   * LEVEL3
 *   * LEVEL4
 *   * LEVEL5
 */
type EscalationLevel =
  'NONE' |
  'LEVEL1' |
  'LEVEL2' |
  'LEVEL3' |
  'LEVEL4' |
  'LEVEL5';
module EscalationLevel {
  export const NONE: EscalationLevel = 'NONE';
  export const LEVEL1: EscalationLevel = 'LEVEL1';
  export const LEVEL2: EscalationLevel = 'LEVEL2';
  export const LEVEL3: EscalationLevel = 'LEVEL3';
  export const LEVEL4: EscalationLevel = 'LEVEL4';
  export const LEVEL5: EscalationLevel = 'LEVEL5';
  export function values(): EscalationLevel[] {
    return [
      NONE,
      LEVEL1,
      LEVEL2,
      LEVEL3,
      LEVEL4,
      LEVEL5
    ];
  }
}

export { EscalationLevel }