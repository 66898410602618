import { Injectable } from '@angular/core';
import { CubejsConfig } from '@cubejs-client/ngx';
import { UserSessionService } from '../../services/session/user-session.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable()
export class AuthenCubeJsService {

    constructor(public userSessionService: UserSessionService) {}

    public config$: Observable<CubejsConfig> = this.userSessionService.authToken$.pipe(
        filter(this.isNotNullOrUndefined),
        take(1),
        map((authToken) => ({
            token: `${localStorage.getItem(environment.dataTokenTypeNameInSession)} ${authToken}`,
            options: {
                apiUrl: environment.cubeUrlApi,
            },
        }))
    );

    private isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
        return input !== null && input !== undefined;
    }

}
