import { LoggerConfig } from 'ngx-logger';
import { environment } from 'src/environments/environment';

export const LOGGER_CONFIG: LoggerConfig = {
    // TODO: uncomment when path available
  // serverLoggingUrl: `${environment.apiUrl}/logs`,
  // httpResponseType: 'json',
  level: environment.browserLogLevel,
  serverLogLevel: environment.serverLogLevel,
  disableConsoleLogging: environment.disableConsoleLogging, // disabled in prod
  timestampFormat: 'full'
};
