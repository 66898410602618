/* tslint:disable */

/**
 * The station's state:
 *   * AVAILABLE:
 *   * UNAVAILABLE:
 *   * PARTIALLY_AVAILABLE:
 *   * UNKNOWN: no connection to the station for a preconfigured time
 */
type StationState =
  'AVAILABLE' |
  'UNAVAILABLE' |
  'PARTIALLY_AVAILABLE' |
  'UNKNOWN';
module StationState {
  export const AVAILABLE: StationState = 'AVAILABLE';
  export const UNAVAILABLE: StationState = 'UNAVAILABLE';
  export const PARTIALLY_AVAILABLE: StationState = 'PARTIALLY_AVAILABLE';
  export const UNKNOWN: StationState = 'UNKNOWN';
  export function values(): StationState[] {
    return [
      AVAILABLE,
      UNAVAILABLE,
      PARTIALLY_AVAILABLE,
      UNKNOWN
    ];
  }
}

export { StationState }