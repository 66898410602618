export class GroupData {
  p;
  q;
  u;
  i;
  vitesse; // N sur l'image

  pression_ch; // pression de la centrale
  t_chy; // température de la centrale

  inj1;
  inj2;
  inj3;
  inj4;

  t_plr_gene_coa; // palier générateur ...
  t_plr_gene_ca;
  t_plr_turbine_ca;
  t_plr_turbine_coa;

  t_phu; // phase
  t_phv;
  t_phw;

  // TODO: replace by null (mock pour démo)
  constructor() {
    this.p = null;
    this.q = null;
    this.u = null;
    this.i = null;
    this.vitesse = null; // N sur l'image

    this.pression_ch = null; // pression de la centrale
    this.t_chy = null; // température de la centrale

    this.inj1 = null;
    this.inj2 = null;
    this.inj3 = null;
    this.inj4 = null;

    this.t_plr_gene_coa = null; // palier générateur ...
    this.t_plr_gene_ca = null;
    this.t_plr_turbine_ca = null;
    this.t_plr_turbine_coa = null;

    this.t_phu = null; // phase
    this.t_phv = null;
    this.t_phw = null;
  }
}
