import { ErrorMessageStyleModel } from './ErrorMessageStyleModel';
import { ErrorMessageDirection } from '../enums/ErrorMessageDirection';

export class ErrorMessageConfig {
  duration: number;
  endOfAnimationToHide: number; // 1000 => 1s
  style: ErrorMessageStyleModel;

  constructor(type: 'static' | 'popup', direction: ErrorMessageDirection) {
    this.duration = 2000;
    this.endOfAnimationToHide = 1000;
    this.style = new ErrorMessageStyleModel(type, direction);
  }
}
