/* tslint:disable */

/**
 * field to filter for events log search:
 *   * ALL : All fields (DATE, TAG, DESCRIPTION, VALUE)
 *   * DATE : the date field
 *   * TAG : The tag field
 *   * DESCRIPTION : The tag description field
 *   * VALUE : The value field
 *   * IS_ALARM
 */
type EventLogSearchFilter =
  'ALL' |
  'DATE' |
  'TAG' |
  'DESCRIPTION' |
  'VALUE' |
  'IS_ALARM';
module EventLogSearchFilter {
  export const ALL: EventLogSearchFilter = 'ALL';
  export const DATE: EventLogSearchFilter = 'DATE';
  export const TAG: EventLogSearchFilter = 'TAG';
  export const DESCRIPTION: EventLogSearchFilter = 'DESCRIPTION';
  export const VALUE: EventLogSearchFilter = 'VALUE';
  export const IS_ALARM: EventLogSearchFilter = 'IS_ALARM';
  export function values(): EventLogSearchFilter[] {
    return [
      ALL,
      DATE,
      TAG,
      DESCRIPTION,
      VALUE,
      IS_ALARM
    ];
  }
}

export { EventLogSearchFilter }