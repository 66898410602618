import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user/user';
import { environment } from 'src/environments/environment';
import { UserProfileSummary, UserType } from 'src/swagger-gen/web/models';
import { AuthService } from '../auth/auth.service';
import { AppGuardType } from '../enum/AppGuardType';
import { FullyLoggedInStateType } from '../enum/FullyLoggedInStateType';
import { ConsumeDataService } from './consume-data.service';
import { LoggedInService } from './loggedIn.service';
import { UserSessionService } from './session/user-session.service';




@Injectable()
export class LoggedInAuthentService extends LoggedInService {

  constructor(@Inject(DOCUMENT) private document: any,
    private httpClient: HttpClient,
    public userSessionService: UserSessionService,
    public consumeDataService: ConsumeDataService,
    public authService: AuthService,
    public router: Router) {
    super(userSessionService, authService, consumeDataService, router);
  }

  public async loggedIn() {
    this.fullyLoggedInState$.next(FullyLoggedInStateType.PENDING);

    const isLoggedIn = await this.isLoggedIn();
    if (!isLoggedIn) {
      // authent okta => jwt => access_token
      // apimanager (access_token) => token
      // appelle apimanager (token)  => user

      this.addResultHandler(await this.authTokenHandler());
      this.addResultHandler(await this.authTokenExpireAtHandler());
      this.addResultHandler(await this.dataTokenTypeHandler());
      this.addResultHandler(await this.dataTokenHandler());
      this.addResultHandler(await this.authUserHandler());
      if (this.isValidResultHandlers()) {
        this.fullyLoggedInState$.next(FullyLoggedInStateType.SUCCESS);
      } else {
        this.userSessionService.cleanSession();
        this.fullyLoggedInState$.next(FullyLoggedInStateType.NO_FULLY_LOGGED_IN);
      }
    } else {
      this.fullyLoggedInState$.next(FullyLoggedInStateType.SUCCESS);
    }
  }

  /**
   * Check if User is already completely logged
   */
  public async isLoggedIn() {
    this.clearResultIsLoggedInList();

    if(environment.authAppGuard !== AppGuardType.NOT_AUTHENTICATED_JUST_LOGGEDIN) {
      this.addResultIsLoggedIn(this.userSessionService.getAuthExpireAt());
      this.addResultIsLoggedIn(this.userSessionService.getAuthToken());
  
      this.addResultIsLoggedIn(this.userSessionService.getDataTokenType());
      this.addResultIsLoggedIn(this.userSessionService.getDataToken());
    }
    this.addResultIsLoggedIn(this.userSessionService.getUser());

    let isValid = this.isValidResultIsLoggedIn();
    if (isValid) {
      // if the expiration has changed: the authService may have changed its token
      // So, the user in not logged in (cause: data in localstorage may be unvalid like authToken)
      const oldExpiredAt = this.userSessionService.getAuthExpireAt();
      const newExpiredAt = await this.generateAuthTokenExpireAt();

      if (oldExpiredAt !== newExpiredAt) {
        // console.log('Authentication expiration has changed');
        isValid = false;
      } else {
        isValid = true;
      }
    }

    return isValid;
  }

  public async refreshAuthTokenExireAt() {
    await this.authTokenExpireAtHandler();
  }

  // ----------------- USER ----------------- //
  protected async getUser(): Promise<UserProfileSummary> {
    return new Promise<UserProfileSummary>(async (resolve, reject) => {

      if(environment.authAppGuard === AppGuardType.NOT_AUTHENTICATED_JUST_LOGGEDIN) {
        setTimeout(() => {
          const rootUser: UserProfileSummary = {
            uid: "root",
            email: "root",
            firstName: environment.firstNameWhenNotAuthenticated,
            lastName: environment.firstNameWhenNotAuthenticated,
            type: UserType.ADMIN,
          }
          resolve(rootUser);
        }, 1000);
      } else {
        // Uncomment this for real login
        this.authorizeUser().subscribe(
          (user: UserProfileSummary) => {
            resolve(user);
          },
          error => {
              resolve(null)
              console.error('Error on getUser() in  on POST authorizeUser'); 
          }
        );
      }
    });
  }

  /**
  * Check user is authorized
  * @param user AuthorizationUser
  * @param tineaToken token got from Api Manager (oauth)
  */
  authorizeUser(): Observable<UserProfileSummary> {
    const userAuthorizeUrl = environment.apiUrl + '/login';
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json')
    };

    return this.httpClient.post<User>(userAuthorizeUrl, null, httpOptions).pipe(
      catchError(this.handleServerError)
    );
  }

  protected handleServerError(badResponse: HttpErrorResponse) {
    console.error('An error occurred in authorizeUser()', badResponse);
    return throwError(badResponse);
  }
}
