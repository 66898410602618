import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableRowModule } from '../tinea-table-row-components/table-row.module';
import { TineaTableComponent } from './table/tinea-table.component';
import { TineaTableRoutingModule } from './tinea-table-routing.module';

@NgModule({
  declarations: [TineaTableComponent],
  imports: [
    CommonModule,
    TableRowModule,
    TineaTableRoutingModule
  ],
  exports: [
    TineaTableComponent
  ]
})
export class TineaTableModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    // library.add(faAngleDown, faAngleDoubleRight, faAngleUp, faHome );
  }
 }
