import { AlertAction } from './alertAction';

export class AlertParameters {
  persistant: boolean;
  withActionButton: boolean;
  withCloseButton: boolean;
  withIcon: boolean;
  withInput: boolean;
  withTimer: boolean;
  withTitle: boolean;
  title?: string;
  icon?: string;
  action?: AlertAction;
  timer?: number; // in ms

  constructor($persistant: boolean, $withActionButton: boolean, $withCloseButton: boolean, $withIcon: boolean,
              $withInput: boolean, $withTimer: boolean, $withTitle: boolean,
              $title?: string, $icon?: string, $action?: AlertAction, $timer?: number) {
    try {
      this.persistant = $persistant;
      this.withCloseButton = $withCloseButton;
      this.withTitle = $withTitle;
      this.withIcon = $withIcon;
      this.withInput = $withInput;
      this.withActionButton = $withActionButton;
      this.withTimer = $withTimer;

      if ($withTitle === true && !!$title) {
        this.title = $title;
      } else {
        if ($withTitle === true && !$title) {
          throw new Error('Provide an alert title or set withTitle parameter to false.');
        }
      }

      if ($withIcon === true && !!$icon) {
        this.icon = $icon;
      } else {
        if ($withIcon === true && !$icon) {
          throw new Error('Provide an alert icon or set withIcon parameter to false.');
        }
      }

      if ($withActionButton === true && !!$action) {
        this.action = $action;
      } else {
        if ($withActionButton === true && !$action) {
          throw new Error('Provide an alert action or set withAction parameter to false.');
        }
      }

      if ($withTimer && !!$timer) {
        this.timer = $timer;
      } else {
        if ($withTimer === true && !$timer) {
          throw new Error('Provide an alert timer or set withtimer parameter to false.');
        }
      }

    } catch (error) {
     // console.log('Error in AlertParameters constructor: ', error);
    }
  }
}
