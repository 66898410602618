import { Router } from '@angular/router';
import { OktaConfig } from '@okta/okta-angular/src/okta/models/okta.config';
import { OktaAuthService } from '@okta/okta-angular';

import { environment } from 'src/environments/environment';

export function onAuthRequiredFunction(oktaAuth, injector) {
  const router = injector.get(Router);
  // Redirect the user to the custom login page
  if (environment.authLoginInternal) {
    router.navigate(['/login']);
  }/* else {
    const path = oktaAuth.getFromUri();
    oktaAuth.loginRedirect(path.uri);
  }*/
}

export const oktaConfig = {
  issuer: environment.okta_issuer,
  clientId: environment.okta_client_id,
  redirectUri: environment.okta_redirectUri,
  pkce: true,
  scopes: [environment.okta_scope],
  onAuthRequired: onAuthRequiredFunction,
  postLogoutRedirectUri: window.location.origin + '/login'
};
