/* tslint:disable */

/**
 * The alarm state:
 *   * DATA_AQUISITION: the event is a tag data acquistion
 *   * ALARM_ACKNOWLEDGEMENT: the aevent is an acknowledgement of an alarm
 *   * ALARM_STATE_UPDATE: the aevent is a alarm state update
 *   * DATA_MIGRATION: the event is from a data migration
 */
type EventType =
  'DATA_AQUISITION' |
  'ALARM_ACKNOWLEDGEMENT' |
  'ALARM_STATE_UPDATE' |
  'DATA_MIGRATION';
module EventType {
  export const DATA_AQUISITION: EventType = 'DATA_AQUISITION';
  export const ALARM_ACKNOWLEDGEMENT: EventType = 'ALARM_ACKNOWLEDGEMENT';
  export const ALARM_STATE_UPDATE: EventType = 'ALARM_STATE_UPDATE';
  export const DATA_MIGRATION: EventType = 'DATA_MIGRATION';
  export function values(): EventType[] {
    return [
      DATA_AQUISITION,
      ALARM_ACKNOWLEDGEMENT,
      ALARM_STATE_UPDATE,
      DATA_MIGRATION
    ];
  }
}

export { EventType }