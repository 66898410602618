export enum iconClass {
    DASHBOARD = 'fas fa-tachometer-alt',
    MAP = 'fas fa-map',
    ALARM = 'fas fa-bell',
    TICKETS = 'fas fa-sticky-note',
    REGIONS = 'fas fa-plug',
    STATIONS = 'fas fa-warehouse',
    GRAPHS = 'fas fa-chart-bar',
    CHARGES = 'fas fa-battery-half',
    SUBSCRIPTIONS = 'fas fa-address-card',
    BADGES = 'far fa-id-badge',
    USERS = 'fas fa-user-alt',
    VEHICLES = 'fas fa-car',
    ADMINISTRATION = 'fas fa-tools',
    EVENTS = 'fas fa-file-alt',
    LOG_OUT = 'fas fa-unlock',
    EMS = 'fas fa-stethoscope',
    LAB = 'fas fa-flask',
    BAR = 'fas fa-signal',
    PIE = 'fas fa-chart-pie',
    BUILDING = 'fas fa-building',
    CLASS = 'fas fa-layer-group',
    CLOSE = 'fas fa-window-close',
    EXPORT = 'fas fa-file-download icon-download',
    GROUP = 'fas fa-columns icon-group',
    SEARCH = 'fas fa-search',
    SORT = 'fas fa-sort',
    REFRESH = 'fas fa-sync-alt',
    WRENCH = 'fas fa-solid fa-wrench',
    PEN = 'fas fa-solid fa-pen',
    DOWN_ARROW = 'fas fa-solid fa-caret-down',
    UP_ARROW = 'fas fa-solid fa-caret-up',
}
