import { ApplicationAuthorization, ApplicationFeature } from "src/swagger-gen/web/models";

export class UserAuthorizationModel {

    feature: ApplicationFeature;
    readOnly: boolean;

    constructor(applicationAuthorization: ApplicationAuthorization) {
        this.feature = applicationAuthorization.feature;
        this.readOnly = applicationAuthorization.readOnly || false;
    }

    public static getListFromApi(res: ApplicationAuthorization[]): UserAuthorizationModel[] {
        const list: UserAuthorizationModel[] = [];
        if (res) {
            res.forEach(r => {
                list.push(new UserAuthorizationModel(r));
            });
        }

        return list;
    }
}