/* tslint:disable */

/**
 * The application features:
 *   * SITE_OPERATION
 *   * SITE_MANAGEMENT
 *   * SUBSCRIPTION
 *   * TRANSACTION
 *   * BADGE
 *   * VEHICLE
 *   * TICKET
 *   * USER
 */
type ApplicationFeature =
  'SITE_OPERATION' |
  'SITE_MANAGEMENT' |
  'SUBSCRIPTION' |
  'TRANSACTION' |
  'BADGE' |
  'VEHICLE' |
  'TICKET' |
  'USER';
module ApplicationFeature {
  export const SITE_OPERATION: ApplicationFeature = 'SITE_OPERATION';
  export const SITE_MANAGEMENT: ApplicationFeature = 'SITE_MANAGEMENT';
  export const SUBSCRIPTION: ApplicationFeature = 'SUBSCRIPTION';
  export const TRANSACTION: ApplicationFeature = 'TRANSACTION';
  export const BADGE: ApplicationFeature = 'BADGE';
  export const VEHICLE: ApplicationFeature = 'VEHICLE';
  export const TICKET: ApplicationFeature = 'TICKET';
  export const USER: ApplicationFeature = 'USER';
  export function values(): ApplicationFeature[] {
    return [
      SITE_OPERATION,
      SITE_MANAGEMENT,
      SUBSCRIPTION,
      TRANSACTION,
      BADGE,
      VEHICLE,
      TICKET,
      USER
    ];
  }
}

export { ApplicationFeature }