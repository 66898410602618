import { Router } from '@angular/router';
import { kMaxLength } from 'buffer';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserSessionService } from '../services/session/user-session.service';

export abstract class AuthService {

  constructor(public userSessionService: UserSessionService,
              public router: Router
              ) { }

  public abstract authenticationState$: Observable<boolean>;

  public abstract isAuthenticated(): Promise<boolean>;

  public abstract login(): void;

  public abstract logout(): void;

  public abstract getAuthToken(): Promise<string>;

  public abstract getExpireAt(): Promise<number>;

  public redirectAfterFullyLoggedIn(): void {
    const url = this.userSessionService.getRedirectTo();
    if (environment.authRedirectToPreviousUrl && url) {
        this.router.navigate([url]);
    } else {
        this.router.navigate([environment.authPathRedirectAfterLogin]);
    }
}
}
