import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError, Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })
export class CustomOktaService {

    authenticationState$: Subject<boolean> = new Subject<boolean>();

    readonly HOST = environment.oktaCustom.okta_issuer;
    readonly WELL_KNOWN_PATH        = '/.well-known/openid-configuration';
    readonly ENDPOINT_AUTHORIZATION = 'authorization_endpoint';
    readonly ENDPOINT_TOKEN         = 'token_endpoint';
    readonly ENDPOINT_USERINFO      = 'userinfo_endpoint';
    readonly ENDPOINT_REGISTRATION  = 'registration_endpoint';
    readonly ENDPOINT_LOGOUT        = 'end_session_endpoint';
    readonly ENDPOINT_JWKS_URI      = 'jwks_uri';
    readonly RESPONSE_TYPE_CODE     = 'code';
    readonly STORAGE_KEY_NAME       = 'okta-custom-storage'; // in localstorage
    readonly TOKEN_KEY_NAME         = 'okta-custom-token'; // in localstorage
    readonly TOKEN_EXPIRE_AT        = 'expireAt';
    // token
    readonly TOKEN_ACCESS_TOKEN     = 'access_token';
    readonly TOKEN_ID_TOKEN         = 'id_token';
    readonly TOKEN_EXPIRE_IN        = 'expires_in';

    constructor(public router: Router,
                private http: HttpClient,
                private activatedRoute: ActivatedRoute) {
    }

    isAuthenticated(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const accessToken = this.getToken();
            if (accessToken) {
                this.authenticationState$.next(true);
                resolve(true);
            } else {
                this.authenticationState$.next(false);
                resolve(false);
            }
        });
    }

    loginRedirect(path: string): void {
        this.redirectToIam();
        return;
    }

    logout(): void {
        const url = this.getLogoutOkta();
        localStorage.removeItem(this.STORAGE_KEY_NAME);
        localStorage.removeItem(this.TOKEN_KEY_NAME);
        window.location.href = url.toString();
    }

    getAccessToken(): Promise<string | undefined> {
        return new Promise<string>((resolve, reject) => {
            const token = this.getToken();
            const accessToken = (token && token[this.TOKEN_ACCESS_TOKEN]) ? token[this.TOKEN_ACCESS_TOKEN] : null;
            if (accessToken) {
                resolve(accessToken);
            } else {
                resolve(undefined);
            }
        });
    }

    getExpireAt(): Promise<string | undefined> {
        return new Promise<string>((resolve, reject) => {
            const tokenData = this.getToken();
            const expireAt = (tokenData && tokenData[this.TOKEN_EXPIRE_AT]) ? tokenData[this.TOKEN_EXPIRE_AT] : null;
            if (expireAt) {
                resolve(expireAt);
            } else {
                resolve(undefined);
            }
        });
    }

    redirectToIam(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.getWellKnownHandler().then(
                wellKnownObj => {
                    if (wellKnownObj) {
                        const autorizationUrl = wellKnownObj.authorization_endpoint;
                        if (autorizationUrl) {
                            const url = new URL(autorizationUrl);
                            url.search = this.getAuthorizeParams();
                            window.location.href = url.href;
                            resolve(true);
                        } else {
                           // console.log('error on getting okta wellk-known: authorization_endpoint doesn\'t exist');
                            resolve(false);
                        }
                    }
                },
                error => {
                    // console.log('error on getting okta wellk-known');
                    return resolve(false);
                }
            );
        });
    }

    getWellKnownHandler(): Promise<any> {
        return new Promise<boolean>((resolve) => {
            this.getWellKnown().toPromise().then(
                wellKnownObj => {
                    this.setWellKnownData(wellKnownObj);
                    const wellKnownObjFromLocalStorage = this.getWellKnownData();
                    resolve(wellKnownObjFromLocalStorage);
                },
                error => {
                    console.log('error on getting okta wellk-known');
                    resolve(undefined);
                }
            );
        });
    }

    getWellKnown(): Observable<any> {
        return this.http.get(this.HOST + this.WELL_KNOWN_PATH).pipe(
            catchError(this.handleServerError)
        );
    }

    setWellKnownData(wellKnownObj: any): void {
        const wellKnownObjString = JSON.stringify(wellKnownObj);
        localStorage.setItem(this.STORAGE_KEY_NAME, wellKnownObjString);
    }

    getWellKnownData(): any {
        const value = localStorage.getItem(this.STORAGE_KEY_NAME);
        return (value && this.isParsable(value)) ? JSON.parse(value) : undefined;
    }

    getAuthorizeParams(): string {
        const params = new URLSearchParams();
        if (environment.oktaCustom.response_type) {
            params.append('response_type', environment.oktaCustom.response_type);
        }
        if (environment.oktaCustom.okta_client_id) {
            params.append('client_id', environment.oktaCustom.okta_client_id);
        }
        if (environment.oktaCustom.redirect_uri) {
            params.append('redirect_uri', environment.oktaCustom.redirect_uri);
        }
        if (environment.oktaCustom.scopes) {
            params.append('scope', environment.oktaCustom.scopes);
        }
        if (environment.oktaCustom.state) {
            params.append('state', environment.oktaCustom.state);
        }
        if (environment.oktaCustom.nonce) {
            params.append('nonce', environment.oktaCustom.nonce);
        }
        if (environment.oktaCustom.code_challenge) {
            params.append('code_challenge', environment.oktaCustom.code_challenge);
        }
        if (environment.oktaCustom.code_challenge_method) {
            params.append('code_challenge_method', environment.oktaCustom.code_challenge_method);
        }

        return params.toString();
    }

    public async getTokenHandler(): Promise<boolean> {
            if (environment.oktaCustom.response_type === this.RESPONSE_TYPE_CODE) {
                const code = await this.getCodeFromUrl();
                if (code) {
                    try {
                        const token = await this.getTokenFromOkta(code).toPromise();
                        if (token) {
                            this.storeToken(token);
                            await this.isAuthenticated();
                            return true;
                        } else {
                            console.error('No token found calling getTokenFromOkta()');
                            return false;
                        }
                    } catch (error) {
                       console.error('error on getTokenFromOkta()');
                       return false;
                    }
                }
            } else {
                console.error('Response_type is unknown by custom okta service');
                return false;
            }
    }

    private getCodeFromUrl(): Promise<string | undefined> {
        return new Promise<string | undefined>((resolve) => {
            this.activatedRoute.queryParams.subscribe(params => {
                const code = params[this.RESPONSE_TYPE_CODE];
                if (code) {
                    resolve(code);
                } else {
                    resolve(undefined);
                }
            });
        });
    }

    private getTokenFromOkta(code: string): Observable<any> {
        const wellKnownData = this.getWellKnownData();
        const url = (wellKnownData && wellKnownData[this.ENDPOINT_TOKEN]) ? wellKnownData[this.ENDPOINT_TOKEN] : null;

        if (url) {
            const bodyParams = this.getTokenParams(code);
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            };

            return this.http.post(url, bodyParams, httpOptions).pipe(
                // tap((token) => console.log(`Got openId token in custom okta service (okta):`, token)), // DEBUG
                catchError(this.handleServerError)
            );
        } else {
            console.error('the endpoint_token is unknown');
        }
    }

    getTokenParams(code: string): string {
        const params = new URLSearchParams();
        if (environment.oktaCustom.grant_type) {
            params.append('grant_type', environment.oktaCustom.grant_type);
        }
        if (code) {
            params.append('code', code);
        }
        if (environment.oktaCustom.okta_client_id) {
            params.append('client_id', environment.oktaCustom.okta_client_id);
        }
        if (environment.oktaCustom.redirect_uri) {
            params.append('redirect_uri', environment.oktaCustom.redirect_uri);
        }
        if (environment.oktaCustom.code_verifier) {
            params.append('code_verifier', environment.oktaCustom.code_verifier);
        }

        return params.toString();
    }

    private isParsable(json: any): boolean {
        try {
            json = JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }

    private storeToken(token: any) {
        if (this.isValidToken(token)) {
            const tokenData = {
                access_token: token[this.TOKEN_ACCESS_TOKEN],
                id_token: token[this.TOKEN_ID_TOKEN],
                expireAt: this.getExpireAtFromToken(token),
            };
            this.setToken(tokenData);
        } else {
            console.error('storeToken(): unvalid token');
        }
    }

    private getExpireAtFromToken(token: any): number {
        const expiresIn = (token[this.TOKEN_EXPIRE_IN]) ? token[this.TOKEN_EXPIRE_IN] : 0;
        const expireAt =  Math.ceil((Date.now() / 1000)) + expiresIn;
        return expireAt;
    }

    private isValidToken(token: any): boolean {
        let isValid = true;
        if (!token[this.TOKEN_ACCESS_TOKEN]) {
            isValid = false;
        }
        if (!token[this.TOKEN_ID_TOKEN]) {
            isValid = false;
        }
        if (!token[this.TOKEN_EXPIRE_IN]) {
            isValid = false;
        }

        return isValid;
    }

    setToken(token: any): void {
        const tokenString = JSON.stringify(token);
        localStorage.setItem(this.TOKEN_KEY_NAME, tokenString);
    }

    getToken(): any {
        const value = localStorage.getItem(this.TOKEN_KEY_NAME);
        return (value && this.isParsable(value)) ? JSON.parse(value) : undefined;
    }

    private getLogoutOkta(): URL {
        const wellKnownData = this.getWellKnownData();
        const urlLogout = (wellKnownData && wellKnownData[this.ENDPOINT_LOGOUT]) ? wellKnownData[this.ENDPOINT_LOGOUT] : null;
        const tokenData = this.getToken();
        const idToken   = (tokenData && tokenData.id_token) ? tokenData.id_token : null;

        if (urlLogout && idToken) {
            const url = new URL(urlLogout);
            const params = this.getLogoutParams(idToken);
            url.search = params;

            return url;
        } else {
            const message = (urlLogout) ? 'the endpoint_logout is unknown' : 'No id token found for getLogoutOkta()' ;
            console.error(message);
        }
    }

    getLogoutParams(id_token_int: string): string {
        const params = new URLSearchParams();
        if (id_token_int) {
            params.append('id_token_hint', id_token_int);
        }
        if (environment.oktaCustom.post_logout_redirect_uri) {
            params.append('post_logout_redirect_uri', environment.oktaCustom.post_logout_redirect_uri);
        }

        return params.toString();
    }

    protected handleServerError(badResponse: HttpErrorResponse) {
        console.error('An error occurred:', badResponse);
        return throwError(badResponse);
      }
}
