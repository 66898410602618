export enum ContentId {
    //sections
    PRECONFIGURED_SECTION,
    AVAILABILITY_SECTION,
    CONNECTIVITY_N_AVAILABILITY_SECTION,
    ALARM_SECTION,
    TRANSACTION_SECTION,
    TICKET_SECTION,

    //graphs
    PRECONFIGURED_GRAPH,
    AVAILABILITY_GRAPH,
    CONNECTIVITY_GRAPH,
    ALARM_GRAPH,
    ALARM_TOGGLE_GRAPH,
    TRANSACTION_COUNT_GRAPH,
    TRANSACTION_VOLUME_GRAPH,
    TICKET_GRAPH,
    TICKET_COUNT_GRAPH,
}