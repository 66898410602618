/* tslint:disable */

/**
 * The error code :
 *   * "00.XXX" : means that an HTTP error code has been return and must be taken into account
 *   * "10.XXX" : means that a database error occurs
 *   * "11.XXX" : means that a data consistency problem occurs
 *   * "12.XXX" : means that too much data have been requested
 *   * "50.XXX" : errors related to user management
 *   * "51.XXX" : errors related to user requests (forgotten password)
 *   * "60.XXX" : errors related to station management
 *   * "61.XXX" : errors related to agent management
 *   * "99.XXX" : other errors
 */
type ErrorCode =
  '00.000' |
  '10.001' |
  '10.002' |
  '10.003' |
  '11.001' |
  '11.002' |
  '11.003' |
  '12.001' |
  '12.003' |
  '50.001' |
  '50.002' |
  '50.003' |
  '50.004' |
  '50.005' |
  '50.006' |
  '50.007' |
  '50.008' |
  '50.009' |
  '50.010' |
  '50.011' |
  '50.012' |
  '51.001' |
  '51.002' |
  '52.001' |
  '52.002' |
  '52.003' |
  '52.004' |
  '53.001' |
  '53.002' |
  '53.003' |
  '53.004' |
  '53.005' |
  '53.006' |
  '60.001' |
  '60.002' |
  '60.003' |
  '60.004' |
  '60.005' |
  '60.006' |
  '60.007' |
  '60.008' |
  '60.009' |
  '60.010' |
  '60.011' |
  '61.001' |
  '61.002' |
  '61.003' |
  '61.004' |
  '62.001' |
  '62.002' |
  '62.003' |
  '62.004' |
  '63.001' |
  '64.001' |
  '64.002' |
  '64.003' |
  '70.001' |
  '70.002' |
  '70.003' |
  '70.004' |
  '70.005' |
  '71.001' |
  '71.002' |
  '72.001' |
  '72.002' |
  '72.003' |
  '72.004' |
  '72.005' |
  '73.001' |
  '73.002' |
  '73.003' |
  '80.001' |
  '80.002' |
  '80.003' |
  '80.004' |
  '81.001' |
  '81.002' |
  '81.101' |
  '82.001' |
  '82.002' |
  '82.003' |
  '82.004' |
  '99.001' |
  '99.002' |
  '99.003' |
  '99.004' |
  '99.005' |
  '99.006' |
  '99.007' |
  '99.999';
module ErrorCode {
  export const _00_000: ErrorCode = '00.000';
  export const _10_001: ErrorCode = '10.001';
  export const _10_002: ErrorCode = '10.002';
  export const _10_003: ErrorCode = '10.003';
  export const _11_001: ErrorCode = '11.001';
  export const _11_002: ErrorCode = '11.002';
  export const _11_003: ErrorCode = '11.003';
  export const _12_001: ErrorCode = '12.001';
  export const _12_003: ErrorCode = '12.003';
  export const _50_001: ErrorCode = '50.001';
  export const _50_002: ErrorCode = '50.002';
  export const _50_003: ErrorCode = '50.003';
  export const _50_004: ErrorCode = '50.004';
  export const _50_005: ErrorCode = '50.005';
  export const _50_006: ErrorCode = '50.006';
  export const _50_007: ErrorCode = '50.007';
  export const _50_008: ErrorCode = '50.008';
  export const _50_009: ErrorCode = '50.009';
  export const _50_010: ErrorCode = '50.010';
  export const _50_011: ErrorCode = '50.011';
  export const _50_012: ErrorCode = '50.012';
  export const _51_001: ErrorCode = '51.001';
  export const _51_002: ErrorCode = '51.002';
  export const _52_001: ErrorCode = '52.001';
  export const _52_002: ErrorCode = '52.002';
  export const _52_003: ErrorCode = '52.003';
  export const _52_004: ErrorCode = '52.004';
  export const _53_001: ErrorCode = '53.001';
  export const _53_002: ErrorCode = '53.002';
  export const _53_003: ErrorCode = '53.003';
  export const _53_004: ErrorCode = '53.004';
  export const _53_005: ErrorCode = '53.005';
  export const _53_006: ErrorCode = '53.006';
  export const _60_001: ErrorCode = '60.001';
  export const _60_002: ErrorCode = '60.002';
  export const _60_003: ErrorCode = '60.003';
  export const _60_004: ErrorCode = '60.004';
  export const _60_005: ErrorCode = '60.005';
  export const _60_006: ErrorCode = '60.006';
  export const _60_007: ErrorCode = '60.007';
  export const _60_008: ErrorCode = '60.008';
  export const _60_009: ErrorCode = '60.009';
  export const _60_010: ErrorCode = '60.010';
  export const _60_011: ErrorCode = '60.011';
  export const _61_001: ErrorCode = '61.001';
  export const _61_002: ErrorCode = '61.002';
  export const _61_003: ErrorCode = '61.003';
  export const _61_004: ErrorCode = '61.004';
  export const _62_001: ErrorCode = '62.001';
  export const _62_002: ErrorCode = '62.002';
  export const _62_003: ErrorCode = '62.003';
  export const _62_004: ErrorCode = '62.004';
  export const _63_001: ErrorCode = '63.001';
  export const _64_001: ErrorCode = '64.001';
  export const _64_002: ErrorCode = '64.002';
  export const _64_003: ErrorCode = '64.003';
  export const _70_001: ErrorCode = '70.001';
  export const _70_002: ErrorCode = '70.002';
  export const _70_003: ErrorCode = '70.003';
  export const _70_004: ErrorCode = '70.004';
  export const _70_005: ErrorCode = '70.005';
  export const _71_001: ErrorCode = '71.001';
  export const _71_002: ErrorCode = '71.002';
  export const _72_001: ErrorCode = '72.001';
  export const _72_002: ErrorCode = '72.002';
  export const _72_003: ErrorCode = '72.003';
  export const _72_004: ErrorCode = '72.004';
  export const _72_005: ErrorCode = '72.005';
  export const _73_001: ErrorCode = '73.001';
  export const _73_002: ErrorCode = '73.002';
  export const _73_003: ErrorCode = '73.003';
  export const _80_001: ErrorCode = '80.001';
  export const _80_002: ErrorCode = '80.002';
  export const _80_003: ErrorCode = '80.003';
  export const _80_004: ErrorCode = '80.004';
  export const _81_001: ErrorCode = '81.001';
  export const _81_002: ErrorCode = '81.002';
  export const _81_101: ErrorCode = '81.101';
  export const _82_001: ErrorCode = '82.001';
  export const _82_002: ErrorCode = '82.002';
  export const _82_003: ErrorCode = '82.003';
  export const _82_004: ErrorCode = '82.004';
  export const _99_001: ErrorCode = '99.001';
  export const _99_002: ErrorCode = '99.002';
  export const _99_003: ErrorCode = '99.003';
  export const _99_004: ErrorCode = '99.004';
  export const _99_005: ErrorCode = '99.005';
  export const _99_006: ErrorCode = '99.006';
  export const _99_007: ErrorCode = '99.007';
  export const _99_999: ErrorCode = '99.999';
  export function values(): ErrorCode[] {
    return [
      _00_000,
      _10_001,
      _10_002,
      _10_003,
      _11_001,
      _11_002,
      _11_003,
      _12_001,
      _12_003,
      _50_001,
      _50_002,
      _50_003,
      _50_004,
      _50_005,
      _50_006,
      _50_007,
      _50_008,
      _50_009,
      _50_010,
      _50_011,
      _50_012,
      _51_001,
      _51_002,
      _52_001,
      _52_002,
      _52_003,
      _52_004,
      _53_001,
      _53_002,
      _53_003,
      _53_004,
      _53_005,
      _53_006,
      _60_001,
      _60_002,
      _60_003,
      _60_004,
      _60_005,
      _60_006,
      _60_007,
      _60_008,
      _60_009,
      _60_010,
      _60_011,
      _61_001,
      _61_002,
      _61_003,
      _61_004,
      _62_001,
      _62_002,
      _62_003,
      _62_004,
      _63_001,
      _64_001,
      _64_002,
      _64_003,
      _70_001,
      _70_002,
      _70_003,
      _70_004,
      _70_005,
      _71_001,
      _71_002,
      _72_001,
      _72_002,
      _72_003,
      _72_004,
      _72_005,
      _73_001,
      _73_002,
      _73_003,
      _80_001,
      _80_002,
      _80_003,
      _80_004,
      _81_001,
      _81_002,
      _81_101,
      _82_001,
      _82_002,
      _82_003,
      _82_004,
      _99_001,
      _99_002,
      _99_003,
      _99_004,
      _99_005,
      _99_006,
      _99_007,
      _99_999
    ];
  }
}

export { ErrorCode }