import { Injectable } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SimpleCryptoService } from 'src/app/tinea-components/okta/services/crypto/simple-crypto.service';
import { User } from 'src/app/shared/models/user/user';
import {RouteId} from '../../../../enums/env/routes-id-enum';
import {FilterByPage} from '../../../../interfaces/filtres/filter-interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  public authToken: string;
  public dataTokenType: string; // like 'Bearer'
  public dataToken: string; // like 'Bearer'
  public user;
  public version: string;
  public authToken$ = new BehaviorSubject<string | null>(null);

  constructor(private router: Router,
              private simpleCryptoService: SimpleCryptoService) { }

  /**
   * Get a value encrypted or not to plain value (depending of environment conf)
   */
  private getEncryptedToDecryptedValue(value, mustDecodeIfObject: boolean) {
    if (environment.encryptUserSession) {
      value = this.simpleCryptoService.decryptByValue(value, mustDecodeIfObject);
    } else if (mustDecodeIfObject) {
      value = (value) ? this.getParsableJson(value) : null ;
    }
    return value;
  }

  /**
   * Get a value decrypted or not to encrypted value (depending of environment conf)
   */
  private getDecryptedToEncryptedValue(value) {
    let newValue = value;
    if (environment.encryptUserSession) {
      newValue = this.simpleCryptoService.encryptByValue(value);
    }
    return newValue;
  }

  /**
   * Check if a value is a valid JSON or return null
   */
  getParsableJson(value: any): boolean | null {
    try {
      const json = JSON.parse(value);
      return json;
    } catch (error) {
      return null;
    }
  }
  public clearSessionSearch() {
    sessionStorage.setItem(environment.search, JSON.stringify([{
      type: RouteId.STATIONS,
      params: []
    }]));
  }
  public getSearch<T>(): FilterByPage<T>[] {

    const searchTab = JSON.parse(sessionStorage.getItem(environment.search)) != null ? JSON.parse(sessionStorage.getItem(environment.search)) : [];
    return searchTab;

  }
  public storeSearch<T>(filterByPage: FilterByPage<T>) {
    // tous les filtres des pages
    const searchAllFilter = this.getSearch<T>();

    // si le tableau n'est pas null(cela peut être le cas si c'est la première co)
    if (searchAllFilter) {
      // vérification de si le filtre de la page en cours existe
      const filterPage = searchAllFilter.find(elm => elm.type === filterByPage.type);

      if (filterPage) { // si elle existe
        for (let i = 0; i < searchAllFilter.length; i++) {
          if (searchAllFilter[i].type === filterByPage.type) {
            searchAllFilter[i].params = filterByPage.params; // modifie la valeur en cours
          }
        }
      } else {
        searchAllFilter.push(filterByPage);
      }
    } else {
      searchAllFilter.push(filterByPage);
    }
    sessionStorage.setItem(environment.search, JSON.stringify(searchAllFilter));
  }
  // Get authentication token from session
  public getAuthToken(): string {
    const authToken = localStorage.getItem(environment.authTokenNameInSession);
    this.authToken$.next(authToken);
    return this.getEncryptedToDecryptedValue(authToken, false);
  }

  // Store authentication token in session
  public storeAuthToken(authToken: string): void {
    authToken = this.getDecryptedToEncryptedValue(authToken);
    this.authToken$.next(authToken);
    localStorage.setItem(environment.authTokenNameInSession, authToken);
  }

  // Get expiration of autentication
  public getAuthExpireAt(): number {
    let expireAtString = localStorage.getItem(environment.authExpireAtNameInSession);
    expireAtString = this.getEncryptedToDecryptedValue(expireAtString, false);
    if (expireAtString) {
      const expireAt = Number(expireAtString);
      const isNumber = (!isNaN(expireAt)) ? true : null;
      if (isNumber) {
        return expireAt;
      }
    }

    return null;
  }

  // Store expiration of authentication in session
  public storeAuthExpireAt(expireAt: number): void {
    const expireAtString = this.getDecryptedToEncryptedValue(expireAt.toString());
    localStorage.setItem(environment.authExpireAtNameInSession, expireAtString);
  }

  // Get token type to consume data. Eg. 'Bearer' for an api
  public getDataTokenType(): string {
    const dataTokenType = localStorage.getItem(environment.dataTokenTypeNameInSession);
    return this.getEncryptedToDecryptedValue(dataTokenType, false);
  }

  // Store token type to consume data in session
  public storeDataTokenType(dataTokenType: string): void {
    dataTokenType = this.getDecryptedToEncryptedValue(dataTokenType);
    localStorage.setItem(environment.dataTokenTypeNameInSession, dataTokenType);
  }

  // Get token used to consume data Eg. a token for an api
  public getDataToken(): string {
    const dataToken = localStorage.getItem(environment.dataTokenNameInSession);
    return this.getEncryptedToDecryptedValue(dataToken, false);
  }

  // Store token used to consume data. Eg. For an API
  public storeDataToken(dataToken: string): void {
    dataToken = this.getDecryptedToEncryptedValue(dataToken);
    localStorage.setItem(environment.dataTokenNameInSession, dataToken);
  }

  // Get User object in session
  public getUser(): User {
    const user = localStorage.getItem(environment.authUserNameInSession);
    const decryptedUser = this.getEncryptedToDecryptedValue(user, true);
    const userModel = new User();
    userModel.initFromSession(decryptedUser);
    return userModel;
  }

  // Store User object in session
  public storeUser(user: string) {
    user = this.getDecryptedToEncryptedValue(user);
    localStorage.setItem(environment.authUserNameInSession, user);
  }

  // Get the app version in session
  public getVersion() {
    return localStorage.getItem(environment.versionNameInSession);
  }

  // Store the app version in session
  public storeVersion(value: string): void {
    localStorage.setItem(environment.versionNameInSession, value);
  }

  // Get the redirectTo in session (redirection after logged in)
  public getRedirectTo() {
    return localStorage.getItem(environment.authRedirectToNameInSession);
  }

  // Store the redirectTo in session (redirection after logged in)
  public setRedirectTo(value: string): void {
    localStorage.setItem(environment.authRedirectToNameInSession, value);
  }

  // Clean User session
  public cleanSession(): void {
    localStorage.removeItem(environment.authTokenNameInSession);
    localStorage.removeItem(environment.authExpireAtNameInSession);
    localStorage.removeItem(environment.dataTokenTypeNameInSession);
    localStorage.removeItem(environment.dataTokenNameInSession);
    localStorage.removeItem(environment.authUserNameInSession);
    localStorage.removeItem(environment.versionNameInSession);
    localStorage.removeItem(environment.versionNameInSession);
    // remove saved search in case some search parameters are not available anymore
    localStorage.removeItem('apiSearch');
    // localStorage.removeItem(environment.authRedirectToNameInSession);
  }

  public versionListener() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd ) {
        this.checkWebappVersion();
      }
    });
  }

  /**
   * Check application version (& update it if new deployment).
   */
  public checkWebappVersion(): boolean {
    let canContinue = true;
    // current webapp version
    const currentVersion = environment.VERSION;
    const storedVersion = this.getVersion();

    if (!currentVersion) {
      console.error('Need to define a "version" in environment');
    } else {
      if (!storedVersion) {
      // console.log('No stored version, setting current version.');
      this.storeVersion(currentVersion);
      // if current version is different from the stored
      } else if (!!storedVersion && (storedVersion !== currentVersion)) {
        console.log('Different version.');
        // clear all, set new version & log back in
        this.cleanSession();
        this.storeVersion(currentVersion);
        document.location.reload();
        canContinue = false;
      }
    }
    return canContinue;
  }

  getCurrentLanguage(): string {
    return localStorage.getItem('language');
  }
}
