/* tslint:disable */

/**
 * The station's visibility:
 *   * HIDDEN: not shown on any map
 *   * PRIVATE: only shown on map for connected users
 *   * PUBLIC: shown on map for everybody
 */
type Visibility =
  'HIDDEN' |
  'PRIVATE' |
  'PUBLIC';
module Visibility {
  export const HIDDEN: Visibility = 'HIDDEN';
  export const PRIVATE: Visibility = 'PRIVATE';
  export const PUBLIC: Visibility = 'PUBLIC';
  export function values(): Visibility[] {
    return [
      HIDDEN,
      PRIVATE,
      PUBLIC
    ];
  }
}

export { Visibility }