import { Injectable, Inject } from '@angular/core';
import { AuthService } from '../auth.service';
import { DOCUMENT } from '@angular/common';
import { OktaAuthService } from '@okta/okta-angular';
import { Observable, Subject } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserSessionService } from '../../services/session/user-session.service';
import { Router } from '@angular/router';
import { CustomOktaService } from '../../services/custom-okta.service';

@Injectable()
export class AuthentWithCustomOktaService extends AuthService {

    authenticationState$: Observable<boolean> = this.oktaAuth.authenticationState$
    .pipe(
        // tap((value) => console.log('test ==>', value)),
        share()
    );

    constructor(@Inject(DOCUMENT) private document: any,
                public oktaAuth: CustomOktaService,
                public userSessionService: UserSessionService,
                public router: Router) {
        super(userSessionService, router);
    }

    public isAuthenticated(): Promise<boolean> {
        return this.oktaAuth.isAuthenticated();
    }

    public login() {
        this.oktaAuth.loginRedirect(environment.authPathRedirectAfterLogin);
    }

    public logout() {
        this.userSessionService.cleanSession();
        this.oktaAuth.logout(); // environment.authPathRedirectAfterLogout
    }

    public async getAuthToken() {
        const accessToken = await this.oktaAuth.getAccessToken();
        return (accessToken) ? accessToken : null;
    }

    public async getExpireAt() {
        const expireAt: any = await this.oktaAuth.getExpireAt();
        return (expireAt) ? expireAt : null;
    }
}
