/* tslint:disable */

/**
 * The type of notification:
 *   * END_OF_CHARGE
 *   * PAYMENT_REFUSED
 *   * NEW_INVOICE
 *   * INVOICE_PAID
 *   * SUBSCRIPTION_RENEWED
 *   * SUBSCRIPTION_EXPIRED
 *   * BOOKING_CONFIRMED
 *   * BOOKING_EXPIRED
 *   * LOW_BALANCE
 */
type NotificationType =
  'END_OF_CHARGE' |
  'PAYMENT_REFUSED' |
  'NEW_INVOICE' |
  'INVOICE_PAID' |
  'SUBSCRIPTION_RENEWED' |
  'SUBSCRIPTION_EXPIRED' |
  'BOOKING_CONFIRMED' |
  'BOOKING_EXPIRED' |
  'LOW_BALANCE';
module NotificationType {
  export const END_OF_CHARGE: NotificationType = 'END_OF_CHARGE';
  export const PAYMENT_REFUSED: NotificationType = 'PAYMENT_REFUSED';
  export const NEW_INVOICE: NotificationType = 'NEW_INVOICE';
  export const INVOICE_PAID: NotificationType = 'INVOICE_PAID';
  export const SUBSCRIPTION_RENEWED: NotificationType = 'SUBSCRIPTION_RENEWED';
  export const SUBSCRIPTION_EXPIRED: NotificationType = 'SUBSCRIPTION_EXPIRED';
  export const BOOKING_CONFIRMED: NotificationType = 'BOOKING_CONFIRMED';
  export const BOOKING_EXPIRED: NotificationType = 'BOOKING_EXPIRED';
  export const LOW_BALANCE: NotificationType = 'LOW_BALANCE';
  export function values(): NotificationType[] {
    return [
      END_OF_CHARGE,
      PAYMENT_REFUSED,
      NEW_INVOICE,
      INVOICE_PAID,
      SUBSCRIPTION_RENEWED,
      SUBSCRIPTION_EXPIRED,
      BOOKING_CONFIRMED,
      BOOKING_EXPIRED,
      LOW_BALANCE
    ];
  }
}

export { NotificationType }