import { NgxLoggerLevel } from 'ngx-logger';
import { TimezoneType } from 'src/app/enums/timezone-type';
import { AppGuardType } from 'src/app/tinea-components/okta/enum/AppGuardType';
import { DataConsumeType } from 'src/app/tinea-components/okta/enum/DataConsumeType';
import packageInfo from '../../package.json';
import { coreEnvironment } from './core/core.environment';
import { dashboardEnvironment } from './core/dashboard.environment';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  core: coreEnvironment,
  production: false,
  VERSION: packageInfo.version,
  timezone: TimezoneType.LOCAL,
  versionNameInSession: 'version',
  apiUrl: '/prod',
  urlPublicWwebsite: '',
  // logs
  disableConsoleLogging: false,
  browserLogLevel: NgxLoggerLevel.TRACE,
  serverLogLevel: NgxLoggerLevel.LOG,
  // encryption
  encryptUserSession: false,
  encryptionSecretKey: '!j5;/q3XsBxA5@,78CLPAw_[TP[.@L67ddf?6bh;?#]&U',
  // auth
  authClass: 'AuthentWithCustomOktaService',
  authAppGuard: AppGuardType.AUTHENTICATED_BY_CUSTOM_OKTA_THEN_LOGGEDIN,
  firstNameWhenNotAuthenticated: "Test",
  lastNameWhenNotAuthenticated: "Test",
  authLoginInternal: false, // eg. login by internal form (true) ? or by an other website (false) ?
  authRedirectToPreviousUrl: true,
  authPathRedirectAfterLogin: '/',
  authPathRedirectAfterLogout: '/',
  authPathLoggedInCallback: 'logged-in-callback',
  // key names
  authRedirectToNameInSession: 'redirectTo',
  authTokenNameInSession: 'authToken',
  authUserNameInSession: 'user',
  authExpireAtNameInSession: 'authExpireAt',
  // consumes api
  dataStartApiCall: ['/prod'],
  dataConsumeType: DataConsumeType.AUTH,
  dataHeaders: [ {nameInEnv: null, headerName: 'Authorization', mustDecode: false, fieldToDecode: 'uid'} ], // data needed in header for consume data [ {nameInEnv: null, headerName: 'Authorization',mustDecode: false, fieldToDecode: null} ]
  // dataHeaders: [{nameInEnv: 'userId', headerName: 'user-id'} ], // if NOT_AUTHENTICATED_JUST_LOGGEDIN
  userId: '00u3k6faptVSGXpBu0i7',// key names
  dataTokenTypeNameInSession: 'tokenType',
  dataTokenTypeValueInSession: 'Bearer',
  dataTokenNameInSession: 'dataToken',
  // okta
  okta_client_id: '0oa3jr2rq6icDR7490i7', // okta app : "SquadSight HydroWatt"
  okta_domain: 'https://tinea.okta-emea.com',
  okta_issuer: `https://tinea.okta-emea.com/oauth2/aus3jr2rv3BxaaGFW0i7`,
  okta_redirectUri: window.location.origin + '/implicit/callback',
  // okta_redirectUri: window.location.origin + '/implicit/callback',
  okta_scope: 'hydrowatt-prod openid',
  brand_name: 'SHIVA DEV',
  // okta custom
  oktaCustom: {
    okta_client_id: '0oa3jr2rq6icDR7490i7', // okta app : "SquadSight HydroWatt"
    okta_domain: 'https://tinea.okta-emea.com',
    okta_issuer: 'https://tinea.okta-emea.com/oauth2/aus3jr2rv3BxaaGFW0i7',
    response_type: 'code',
    redirect_uri: window.location.origin + '/implicit/custom-callback',
    post_logout_redirect_uri: window.location.origin + '/',
    scopes: 'hydrowatt-prod openid',
    state: 'YYYY',
    nonce: 'YYYY',
    code_challenge: 'qrvgapdkr4CEacapa6oYCcU7BmDKBOApC2PHAAndgGY',
    code_verifier: 'id72dKsrgJQltBfrXkKu-0z-BHZCg5hmSsodywdn1VxeAGcjj9k4RxGjrAOGcxK4Lq8i3U7sssbx1D4bNIhEpcx_B5ohFc2-O1dcTbrvq_~2Ygr9aKku2dEPQHLku1g_',
    code_challenge_method: 'S256',
    grant_type: 'authorization_code',
  },
  // api manager
  getTokenBodyParams: JSON.stringify(['grant_type=urn:ietf:params:oauth:grant-type:jwt-bearer', 'assertion']),
  application : '',
  // keyId: 'a01f30b7-9080-4b37-913d-f0bc9587e14f',
  client_id: '',
  // search
  search: 'search',
  // client_secret: '707c516d-6353-42b3-b42f-863b14c1109f',
  tineaTokenUrl: '',
  // configs
  isEditableCreatable : {
    vehicle: false
  },
  // cube js
  urlDashboard: 'https://hydrowatt-dashboard.digital.equans.com',
  cubeUrlApi: '/cubejs',

  // ENERGIES
  energyTypeIconsPath: {
    ELECTRICITY: '/assets/images/energies/electricity.svg',
    HYDROGEN: '/assets/images/energies/hydrogen.svg',
    GNL: '/assets/images/energies/gas.svg',
    GNC: '/assets/images/energies/gas.svg',
    GNCL: '/assets/images/energies/gas.svg',
    BIOGNC: '/assets/images/energies/gas.svg'
  },
  globalVehiculeEnergyPath: {
    ELECTRICITY: '/assets/images/energies/electricity.svg',
    HYDROGEN: '/assets/images/energies/hydrogen.svg',
    GAS: '/assets/images/energies/gas.svg',
    HYBRID: '/assets/images/energies/hybrid.svg',
  },
  iconMapCluster: "/assets/images/energies/cluster.svg",

  dashboard: dashboardEnvironment,

  regions: [
    {
      name: 'Alpes Sud',
      uid: 'a3cbd9c3-7bbf-4767-ac17-a00b29323bee' ,
      contactName: null,
      contactNumber: null,
      contactEmail: null,
      idSiteList: [
        '09034239-6224-429e-b07a-a00b02c43d35',
        '6380ca0e-619e-4fbd-8aeb-a3fa3d5fefa8',
        'bb0fb02d-8842-479e-bfbc-fbac07b82227',
        'fe96363d-e233-4db6-b5cd-17e91f2addf8'
      ]
    },
    {
      name: 'Grand Ouest',
      uid: '45bfe3ec-4e50-4f5b-9825-bf346d17130b' ,
      contactName: null,
      contactNumber: null,
      contactEmail: null,
      idSiteList: [
        'bc12cd28-6081-400f-bb0d-7014993ca03b',
        '334b6059-b2de-4873-b92b-f9cfbebc0f2e'
      ]
    },
    {
      name: 'Massif Central / Sud',
      uid: '97610c84-4707-46e3-a1d0-36b79db59234' ,
      contactName: 'Frédéric Concé',
      contactNumber: null,
      contactEmail: 'frederic.conce@hydrowatt.fr',
      idSiteList: [
        'd7c1932b-85ae-4ce3-80ee-628907612071',
        '3a5b0f43-d119-4bc8-ad66-ba284fa83085',
        '310cf94d-71bb-4c3b-bf9d-9a40ec27b3c4',
        '1d7019aa-ab67-41e9-857d-84dc5a40a109',
        'ad5de176-9425-4fbb-a7d9-42bbf2187743',
        '017b44d2-a6ca-4c29-848f-2f07297060d6',
        '2c36056b-0d1b-45d0-bc9a-b0f4a2dda62a',
        'e773beed-204e-42c0-a9db-06dc25379cad',
      ]
    },
    {
      name: 'Nord Est',
      uid: '504f9006-d1a5-47d5-b852-1d17a8fb22c5' ,
      contactName: 'Christian Spieldenner',
      contactNumber: null,
      contactEmail: 'Christian.spieldenner@hydrowatt.fr',
      idSiteList: [
        '10171714-7fc9-40a5-8fa1-428acc324e22',
        'e963ff4a-fd0d-42cd-a6db-13c8bddca9dc',
        '8e41ff69-83cb-4706-9dfd-90df202a6fb6',
        'ed625928-63fa-41cd-bacf-0801e8b65ce3',
        '7d3c688c-7aa0-4be0-bc60-04fb897d5074',
        '8abdbb91-c738-4900-b1f7-c50d7fa585a1',
      ]
    },
    {
      name: 'Pyrénées / Corse',
      uid: 'd1f61c2d-6492-4f99-8d3b-e9e54a9e5e87' ,
      contactName: null,
      contactNumber: null,
      contactEmail: null,
      idSiteList: [
        '27887b75-c05e-410c-aea8-654bde47bcd6',
        'd8236718-19c5-4d5b-9f86-b895d9e822b3',
        '6aa91247-3e4a-4bbb-b096-52de60420320',
        'fe646dad-6939-4d7d-a296-870b1751fd66',
        'c7de72ac-fde3-48e9-adac-fac4db454132',
        'a752b545-2d68-469e-927f-56ae8cca95ec',
        '003404df-add7-419f-bb20-8e8a150666cb',
        '32df2d80-2a3c-4990-9ba5-59aebf193413',
        '4ff64f3a-5443-408f-9139-6be2506b0eab',
      ]
    },
    {
      name: 'Seine',
      uid: '0834b4bf-320f-46be-bd5c-73593f70901e' ,
      contactName: null,
      contactNumber: null,
      contactEmail: null,
      idSiteList: [
        '5af67ae3-3bc7-4490-a8aa-ed3c44516807',
        '4697fef2-b72a-4a83-86e1-d01f17ca0ec5'
      ]
    },
    {
      name: 'Sud Est',
      uid: 'a21c7a97-97de-4357-be68-bd7eb01898de' ,
      contactName: 'Maxence Malsert',
      contactNumber: null,
      contactEmail: 'maxence.malsert@hydrowatt.fr',
      idSiteList: [
        '6fdd9d94-da83-415e-ae76-cc0860a2882e',
        '7400e261-d1d6-46fd-bc0f-7b296cc61e22',
        'cb3cbe5e-7cda-4fdd-8651-03816d917965',
        '3924a2e7-ccf3-4a6a-b512-df482e89a9ac',
        'b3620929-f34d-4a17-b663-5dbca06b1772',
        '28077d98-d732-41c1-baef-2016eee46be9',
        '6abca09d-aa48-453d-b97f-8dbbde7bc399',
        '9bd84cf4-ffc8-4c78-919d-47917fee590a',
        '60bfda73-507a-4cbd-a1ee-f48fa7e8c6e6'
      ]
    },
  ],
  stationTags: [
    {
      uid: '310cf94d-71bb-4c3b-bf9d-9a40ec27b3c4', // herbeil
      tags: [
        { name: '0:Herbeil.P_usine', uid:"3f7e2214-d65a-4f5a-bdcd-3b160d802fbf", type: 'power' },
        { name: '0:Herbeil.Niv_amont_MeC', uid: 'c353c8bf-4458-4807-b4b4-183e4917faaa', type: 'water' }
      ]
    },
    {
      uid: 'ad5de176-9425-4fbb-a7d9-42bbf2187743', // peyrusse
      tags: [
        { name: '0:Peyrusse.P_usine', uid: '299dd493-dbc4-4d14-a241-dbdecc0bafba', type: 'power' },
        { name: '0:Peyrusse.Niv_Aval_Gr', uid: 'cb8069c3-4811-4bc4-92ba-5a9f821a58b8', type: 'water' }
      ]
    },
    {
      uid: 'd7c1932b-85ae-4ce3-80ee-628907612071', // Cantagrel
      tags: [
        { name: '0:Cantagrel.M_PAC_g0', uid: '39fd2b4f-d426-4cca-b95c-ca7695097cac', type: 'power' },
        { name: '0:Cantagrel.niv_rel_bar_mm', uid: '445c6ffb-7d22-49ba-9a08-12efcf228aab', type: 'water' }
      ]
    },
    {
      uid: '8abdbb91-c738-4900-b1f7-c50d7fa585a1', // Niederbourg
      tags: [
        { name: '0:Niederbourg.P_Reseau', uid: 'b37f55ad-8a89-4c75-9cd4-fa903908659f', type: 'power' },
        { name: '0:Niederbourg.Niveau_amont', uid: '68873668-86dd-4f89-856e-c0e884ed89ae', type: 'water' }
      ]
    },
    {
      uid: '32df2d80-2a3c-4990-9ba5-59aebf193413', // Urs
      tags: [
        { name: '0:Urs.P_usine', uid: '94f2554f-341e-478a-9d90-1ac1adcff912', type: 'power' },
        { name: '0:Urs.Niv_Amont', uid: '963d632d-2182-41f4-9ce1-3fa02d4a1a47', type: 'water' }
      ]
    },
    {
      uid: '6abca09d-aa48-453d-b97f-8dbbde7bc399', // Sirod
      tags: [
        { name: '0:Sirod.mes_p_bt', uid: '3125a1e9-bb51-48a4-8127-7f19c1756d65', type: 'power' },
        { name: '0:Sirod.mes_niv_eau', uid: '50ba7761-d535-49a2-acb0-724dc0b491ae', type: 'water' }
      ]
    },
    {
      uid: '60bfda73-507a-4cbd-a1ee-f48fa7e8c6e6', // Tours_en_savoie
      tags: [
        { name: '0:Tours_en_savoie.P_usine', uid: 'ae838c62-518e-4bbc-9a4a-9870d481e61e', type: 'power' },
        { name: '0:Tours_en_savoie.Niv_amont_MeC', uid: '6db73ba5-a277-4f5b-983c-28a3ff21b79b', type: 'water' }
      ]
    },
    {
      uid: '27887b75-c05e-410c-aea8-654bde47bcd6', // Albies
      tags: [
        { name: '0:Albies.P_usine', uid: 'f4d64681-8942-40d8-b92e-2e57a3900414', type: 'power' },
        { name: '0:Albies.Niv_Amont', uid: '3974d5ac-2527-4d74-b0ac-9fe4b00782c0', type: 'water' }
      ]
    },
    {
      uid: '6fdd9d94-da83-415e-ae76-cc0860a2882e', // Chapogeres
      tags: [
        { name: '0:Chapogeres.P_usine', uid: 'dd96da63-4bf1-4173-96f2-e5d736508b40', type: 'power' },
        { name: '0:Chapogeres.Niv_Amont', uid: '1c260073-165c-4a49-9a54-360222d77aa6', type: 'water' }
      ]
    },
    {
      uid: '10171714-7fc9-40a5-8fa1-428acc324e22', // Bayard
      tags: [
        { name: '0:Bayard.P_usine', uid: '0cb76b6c-2dbe-4039-833f-129bd3341d9c', type: 'power' },
        { name: '0:Bayard.Niveau_amont', uid: 'b33d0de3-8b36-461a-97c6-3a3ffb383646', type: 'water' }
      ]
    },
    {
      uid: '09034239-6224-429e-b07a-a00b02c43d35', // La Meije 03 janv 2022
      tags: [
        { name: '0:Meije.P_usine', uid: '97e3f401-8437-4c94-a92e-974de28052a4', type: 'power' },
        { name: '0:Meije.Niv_Amont', uid: '23f46819-3778-45e6-8bca-85f02c843c1c', type: 'water' }
      ]
    },
    {
      uid: '3a5b0f43-d119-4bc8-ad66-ba284fa83085', // Fans 03 janv 2022
      tags: [
        { name: '0:Fans.Puis_kw', uid: 'c1657634-c9a2-4ccf-a46f-b5a2463b4928', type: 'power' },
        { name: '0:Fans.Niv_reel_amont_bar', uid: '9118dcde-8fba-41b0-a089-edab3732f0d3', type: 'water' }
      ]
    },
    {
      uid: '7d3c688c-7aa0-4be0-bc60-04fb897d5074', // Lac du Der 03 janv 2022
      tags: [
        { name: '0:Lac_du_der.P_usine', uid: '8e67c9fa-fd8b-44f9-98a6-3a7033ed5788', type: 'power' },
        { name: '0:Lac_du_der.Niv_Amont', uid: '66d15712-24d8-4ac1-8321-c6b11532b559', type: 'water' }
      ]
    },
    {
      uid: 'd8236718-19c5-4d5b-9f86-b895d9e822b3', // Cazamozza 03 janv 2022
      tags: [
        { name: '0:Cazamozza.P_usine', uid: 'a7924714-9e1b-4824-a360-32224d135de3', type: 'power' },
        { name: '0:Cazamozza.Niv_Amont', uid: 'aa7b8a90-44df-4f55-9ac6-078323abb296', type: 'water' }
      ]
    },
    {
      uid: '6aa91247-3e4a-4bbb-b096-52de60420320', // Castillon 03 janv 2022
      tags: [
        { name: '0:Castillon.SCANA_Puissance_active_totale', uid: '78c08195-8017-42b8-8587-7287f27a5fac', type: 'power' },
        { name: '0:Castillon.Niv_Amont_Com', uid: 'c7a999bc-e9b6-490e-a259-c9cc77f22922', type: 'water' }
      ]
    },
    {
      uid: 'fe646dad-6939-4d7d-a296-870b1751fd66', // Giuncaggio 03 janv 2022
      tags: [
        { name: '0:Giuncaggio.P_Usine', uid: '0c8da605-0cd5-4086-9146-b185cc69a420', type: 'power' },
        { name: '0:Giuncaggio.Niveau_amont', uid: 'ac69b613-e6ed-44bd-a030-eee501a7c2d0', type: 'water' }
      ]
    },
    {
      uid: 'c7de72ac-fde3-48e9-adac-fac4db454132', // Ledar 03 janv 2022
      tags: [
        { name: '0:Ledar.P_usine', uid: '3339a34e-ddd0-4272-afd3-d14cc042c071', type: 'power' },
        { name: '0:Ledar.Niveau_Amont', uid: '08cbb608-eb42-452b-bb01-5ef085554c3a', type: 'water' }
      ]
    },
    {
      uid: '4ff64f3a-5443-408f-9139-6be2506b0eab', // Villedubert 03 janv 2022
      tags: [
        { name: '0:Villedubert.Puissance_groupe', uid: '286e1c5f-d92d-431e-9771-3e44c58674ce', type: 'power' },
        { name: '0:Villedubert.Niveau_amont', uid: '66186215-7c4e-4677-93c7-688bcc467a84', type: 'water' }
      ]
    },
    {
      uid: '5af67ae3-3bc7-4490-a8aa-ed3c44516807', // Portmort 03 janv 2022
      tags: [
        { name: '0:Portmort.Puiss_Total_Centrale', uid: '880d4022-38d8-4d18-8cb0-1abf98c74159', type: 'power' },
        { name: '0:Portmort.Niveau_Amont', uid: '54c1f147-e4e6-48ec-8c04-2feccd5aa115', type: 'water' }
      ]
    },
    {
      uid: 'cb3cbe5e-7cda-4fdd-8651-03816d917965', // Cran_gevrier 03 janv 2022
      tags: [
        { name: '0:Cran_gevrier.P_G1', uid: 'b48d0142-4fa5-4fe9-961c-36d77144dff4', type: 'power' },
        { name: '0:Cran_gevrier.Niv_Amont', uid: '034c74f9-36fd-45bf-9c81-a624dfc374f3', type: 'water' }
      ]
    },
    // {
    //   uid: '', // Villemagne 03 janv 2022
    //   tags: [
    //     { name: '0:Villemagne.PUISSANCE', uid: '', type: 'power' },
    //     { name: '0:Villemagne.Niv_eau', uid: '', type: 'water' }
    //   ]
    // },
    {
      uid: '4697fef2-b72a-4a83-86e1-d01f17ca0ec5', // Poses 04 janv 2022
      tags: [
        { name: '0:Poses.PuissTotal', uid: '8c774d56-c737-498b-b8d8-6506e19b1c5d', type: 'power' },
        { name: '0:Poses.NiveauAmont', uid: 'd0416d8b-7e1c-4158-8b05-025708193d43', type: 'water' }
      ]
    },
    //{
    //  uid: '', // Rudet 04 janv 2022 PB CARACTERES TAGS
    //  tags: [
    //    { name: '0:Rudet.EA18_Puissance_KW', uid: '', type: 'power' },
    //    { name: '0:Rudet.EA9_Niveau_d'eau_cm', uid: '', type: 'water' }
    //  ]
    //},
    {
      uid: '3b443626-94d3-420f-937b-8b993806d91c', // Mezos 04 janv 2022 FAIRE SOLAIRE
      tags: [
        { name: '0:Mezos.P_Poste_20kV', uid: '4b43877b-a437-43ab-a560-7d7f7f390aa0', type: 'power' },
        { name: '0:Mezos.XBT_Irradiation_Pourcen', uid: '991ecc8a-1eb7-4c95-bb6b-c7227443987f', type: 'irradiance' }
      ]
    },
    {
      uid: '1d7019aa-ab67-41e9-857d-84dc5a40a109', // Lescure 02 mai 2022
      tags: [
        { name: '0:Lescure.HMI_PV_3', uid: '1bb2f859-ec9a-4e03-bc52-fb171177dce0', type: 'power' },
        { name: '0:Lescure.Niv_Bar_mm', uid: '98cee385-e524-4002-9408-a25c3389956d', type: 'water' }
      ]
    },
    {
      uid: 'a752b545-2d68-469e-927f-56ae8cca95ec', // Luzenac 02 mai 2022
      tags: [
        { name: '0:Luzenac.P_usine', uid: 'ec984c7a-d7cc-485c-8704-d23e5b03fc14', type: 'power' },
        { name: '0:Luzenac.Niv_Amont', uid: 'c0c204a6-1670-425e-8435-8992fff4c534', type: 'water' }
      ]
    },
    {
      uid: '6380ca0e-619e-4fbd-8aeb-a3fa3d5fefa8', // La Murette 02 mai 2022
      tags: [
        { name: '0:Murette.Puissance_p', uid: '0675a629-4c6d-4b53-bd0f-a9b7953b1393', type: 'power' },
        { name: '0:Murette.Mesure', uid: '2b3cc1ff-75ea-4e75-b4ca-4eac6c615a3c', type: 'water' }
      ]
    },
    {
      uid: '003404df-add7-419f-bb20-8e8a150666cb', // Palouma 02 mai 2022
      tags: [
        { name: '0:Palouma.SC_Ana_P', uid: '01b406ac-e515-42da-b87c-0d0fbea1b61a', type: 'power' }/*,
        { name: '', uid: '', type: 'water' }*/
      ]
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
