import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { CustomOktaService } from '../../services/custom-okta.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-okta-custom-call-back',
  templateUrl: './okta-custom-call-back.component.html',
  styleUrls: ['./okta-custom-call-back.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OktaCustomCallBackComponent implements OnInit {

  isLoading: boolean;
  isAuthenticated: boolean;

  constructor(public router: Router,
              private customOktaService: CustomOktaService,
              private authService: AuthService, private oktaAuthService: OktaAuthService) {
  }

  ngOnInit() {
    this.getTokenHandler();
  }


  async getTokenHandler() {
      this.isLoading = true;
      this.isAuthenticated = await this.customOktaService.getTokenHandler();
      this.isLoading = false;
      if (this.isAuthenticated) {
        this.router.navigate([environment.authPathLoggedInCallback]);
      }
  }

  retry() {
    this.authService.login();
  }

  // reconnect() {
  //   localStorage.clear();
  //   this.oktaAuthService.logout();
  // }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event?: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.retry();
    }
  }
}
