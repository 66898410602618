/* tslint:disable */

/**
 * field to filter for stations search:
 *   * ALL : All fields
 *   * NAME : the station's name
 *   * ENERGY : the station's energy
 *   * CITY : the station's city
 *   * STATE : the station's state
 *   * NETWORK : the network's name
 *   * CODE : the network's code
 */
type StationSearchFilter =
  'ALL' |
  'NAME' |
  'ENERGY' |
  'CITY' |
  'STATE' |
  'NETWORK' |
  'CODE';
module StationSearchFilter {
  export const ALL: StationSearchFilter = 'ALL';
  export const NAME: StationSearchFilter = 'NAME';
  export const ENERGY: StationSearchFilter = 'ENERGY';
  export const CITY: StationSearchFilter = 'CITY';
  export const STATE: StationSearchFilter = 'STATE';
  export const NETWORK: StationSearchFilter = 'NETWORK';
  export const CODE: StationSearchFilter = 'CODE';
  export function values(): StationSearchFilter[] {
    return [
      ALL,
      NAME,
      ENERGY,
      CITY,
      STATE,
      NETWORK,
      CODE
    ];
  }
}

export { StationSearchFilter }