import { Injectable } from '@angular/core';
import SimpleCrypto from 'simple-crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SimpleCryptoService  {

  readonly secretKey = environment.encryptionSecretKey;
  public simpleCrypto: SimpleCrypto;

  constructor() {
    this.simpleCrypto = new SimpleCrypto(this.secretKey);
  }

  /**
   * Decode encrypted value
   * @param value encrypted value
   * @param mustDecodeIfObject convert string to object ? (string is an unparsed json)
   */
  public decryptByValue(value: string, mustDecodeIfObject: boolean) {
    try {
      if (value) {
        const decryptedValue = this.simpleCrypto.decrypt(value, mustDecodeIfObject);
        if (decryptedValue === '' && value !== '') {
          return null;
        } else {
          return decryptedValue;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  /**
   * Encryption from plain text or object
   * @param value object | string | number | boolean
   */
  public encryptByValue(value: object | string | number | boolean) {
    const encryptedValue = this.simpleCrypto.encrypt(value);
    return encryptedValue;
  }
}
