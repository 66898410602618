/* tslint:disable */

/**
 * reporting frequency:
 *   * DAILY
 *   * WEEKLY
 *   * FORTNIGHTLY
 *   * MONTHLY
 *   * YEARLY
 */
type ReportFrequency =
  'DAILY' |
  'WEEKLY' |
  'FORTNIGHTLY' |
  'MONTHLY' |
  'YEARLY';
module ReportFrequency {
  export const DAILY: ReportFrequency = 'DAILY';
  export const WEEKLY: ReportFrequency = 'WEEKLY';
  export const FORTNIGHTLY: ReportFrequency = 'FORTNIGHTLY';
  export const MONTHLY: ReportFrequency = 'MONTHLY';
  export const YEARLY: ReportFrequency = 'YEARLY';
  export function values(): ReportFrequency[] {
    return [
      DAILY,
      WEEKLY,
      FORTNIGHTLY,
      MONTHLY,
      YEARLY
    ];
  }
}

export { ReportFrequency }