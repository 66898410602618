/* tslint:disable */

/**
 * The data value type :
 *   * TEXT : means that the value to acquire is textual
 *   * BOOLEAN : means that the value to acquire is a boolean (0 for false, 1 for true)
 *   * INTEGER : means that the value to acquire is an integer
 *   * DECIMAL : means taht the value to acquire is decimal
 */
type DataValueType =
  'TEXT' |
  'BOOLEAN' |
  'INTEGER' |
  'DECIMAL';
module DataValueType {
  export const TEXT: DataValueType = 'TEXT';
  export const BOOLEAN: DataValueType = 'BOOLEAN';
  export const INTEGER: DataValueType = 'INTEGER';
  export const DECIMAL: DataValueType = 'DECIMAL';
  export function values(): DataValueType[] {
    return [
      TEXT,
      BOOLEAN,
      INTEGER,
      DECIMAL
    ];
  }
}

export { DataValueType }