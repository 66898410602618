import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { UserSessionService } from 'src/app/tinea-components/okta/services/session/user-session.service';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  constructor(public userSessionService: UserSessionService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const canGoOn = this.userSessionService.checkWebappVersion();
    // console.log('Checking version in interceptor, is ok:', canGoOn);
    if (canGoOn) {
      return next.handle(request);
    } else {
      return EMPTY;
    }
  }
}
