import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AxwayService } from './axway.service';
import { UserSessionService } from './session/user-session.service';
import { HttpRequest } from '@angular/common/http';
import { DataConsumeType } from '../enum/DataConsumeType';

import jwtDecode, { JwtDecodeOptions } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class ConsumeDataService {

    constructor(private axwayService: AxwayService,
                private userSessionService: UserSessionService) { }

    public getDataTokenTypeFromEnv(): string {
        return environment.dataTokenTypeValueInSession;
    }

    public getDataTokenType(): string {
        return this.userSessionService.getDataTokenType();
    }

    public async getDataToken(): Promise<string> {
        let token = null;
        if (environment.dataConsumeType === DataConsumeType.AXWAY) {
            token = await this.getTokenFromAxway();
        } else if (environment.dataConsumeType === DataConsumeType.AUTH) {
            token = await this.getTokenFromAuth();
        } else if (environment.dataConsumeType === DataConsumeType.APIM_TINEA) {
            token = this.getTokenFromApimTinea();
        } else {
            console.error('environment.dataConsumeType value unknown');
        }

        return token;
    }

    /**
     * Get token returned by authentication
     */
    private async getTokenFromAuth(): Promise<string> {
        const token = await this.userSessionService.getAuthToken();
        return token;
    }

    /**
     * Get token returned by axway
     */
    private async getTokenFromAxway(): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            this.axwayService.postToken(this.userSessionService.getAuthToken()).subscribe(
              response => {
                const accessToken = response.access_token;
                resolve(accessToken);
              },
              error => {
                resolve(null);
              }
            );
          });
    }

    /**
     * No token used
     */
    private async getTokenFromApimTinea(): Promise<string> {
        const token = '';
        return token;
    }

    /**
     * Get 'Authorization' header value for api call where the url called begins by environment settings
     * @param request request come from interceptor
     */
    public getHeaderForConsumeData(request: HttpRequest<any>): any {
        const url = request.url;
        const dataheader = {};

        if (url) {
            const dataTokenType      = this.getDataTokenType();
            const dataTokenInSession = this.getDataTokenInSession();

          
            const separator = (dataTokenType !== '') ? ' ' : '';

            for (const startApi of environment.dataStartApiCall) {
                if (url.startsWith(startApi + '/')) {
                    for (const headerData of environment.dataHeaders) {
                        // value is null if is the token
                        let value = environment[headerData.nameInEnv];
                        let headerName = headerData.headerName;
                        // is the token & token exist
                        if(headerData.mustDecode) {
                            const userId = this.decodeJwtToken(dataTokenInSession, headerData.fieldToDecode);
                            value = userId;
                        }else {
                            if (!value && dataTokenInSession) {
                                value = dataTokenType + separator + dataTokenInSession;
                            }  
                        }
                        
                        dataheader[headerName] = value;
                    }
                    return dataheader;
                }
            }
        }

        return dataheader;
    }

    private decodeJwtToken(dataTokenInSession: string, fieldToDecode: string) {
        switch(fieldToDecode) {
            case "uid" : 
                const decoded: any = jwtDecode(dataTokenInSession);
                return decoded.uid;
        }
        return null;  
    }

    private getDataTokenInSession(): string {
        return this.userSessionService.getDataToken();
    }
}
