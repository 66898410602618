/* tslint:disable */

/**
 * Indicates the matching to consider:
 *   * EXACT_MATCH : a case sensitive exact matching
 *   * EXACT_MATCH_IGNORE_CASE : a case insensitive exact match
 *   * PARTIAL_MATCH : a case sensitive partial matching
 *   * PARTIAL_MATCH_IGNORE_CASE : a case insensitive partial matching
 */
type MatchingType =
  'EXACT_MATCH' |
  'EXACT_MATCH_IGNORE_CASE' |
  'PARTIAL_MATCH' |
  'PARTIAL_MATCH_IGNORE_CASE';
module MatchingType {
  export const EXACT_MATCH: MatchingType = 'EXACT_MATCH';
  export const EXACT_MATCH_IGNORE_CASE: MatchingType = 'EXACT_MATCH_IGNORE_CASE';
  export const PARTIAL_MATCH: MatchingType = 'PARTIAL_MATCH';
  export const PARTIAL_MATCH_IGNORE_CASE: MatchingType = 'PARTIAL_MATCH_IGNORE_CASE';
  export function values(): MatchingType[] {
    return [
      EXACT_MATCH,
      EXACT_MATCH_IGNORE_CASE,
      PARTIAL_MATCH,
      PARTIAL_MATCH_IGNORE_CASE
    ];
  }
}

export { MatchingType }