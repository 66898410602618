/* tslint:disable */

/**
 * Group name for events log filter:
 *   * TAG : The tag field
 *   * DESCRIPTION : The tag description field
 *   * DATE : The date field
 *   * VALUE : The value field
 *   * GROUP : The tag group field
 */
type EventsLogFieldGroupType =
  'TAG' |
  'DESCRIPTION' |
  'DATE' |
  'VALUE' |
  'GROUP';
module EventsLogFieldGroupType {
  export const TAG: EventsLogFieldGroupType = 'TAG';
  export const DESCRIPTION: EventsLogFieldGroupType = 'DESCRIPTION';
  export const DATE: EventsLogFieldGroupType = 'DATE';
  export const VALUE: EventsLogFieldGroupType = 'VALUE';
  export const GROUP: EventsLogFieldGroupType = 'GROUP';
  export function values(): EventsLogFieldGroupType[] {
    return [
      TAG,
      DESCRIPTION,
      DATE,
      VALUE,
      GROUP
    ];
  }
}

export { EventsLogFieldGroupType }