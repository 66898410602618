import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AlertParameters, AlertType } from 'src/app/shared/models/models-index';
import { connect } from 'echarts';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  /** Default value for showing the notification or not */
  displayDefault = false;

  /** Enum import: INFO / WARNING / ERROR */
  notificationType = AlertType;
  /** Default type of notification */
  typeDefault: AlertType = this.notificationType.INFO;

  /** Default message for notification */
  messageDefault = '';

  /** Default parameters object for notification */
  paramsDefault = new AlertParameters(
    false,
    false,
    false,
    false,
    false,
    false,
    false
  );

  /* Behaviour subjects to change values each time it is requested */
  private displaySource = new BehaviorSubject(this.displayDefault);
  private typeSource = new BehaviorSubject(this.typeDefault);
  private messageSource = new BehaviorSubject(this.messageDefault);
  private paramsSource = new BehaviorSubject(this.paramsDefault);

  /* Observables you can subscribe to in Components to get current state*/
  currentMessage = this.messageSource.asObservable();
  currentType = this.typeSource.asObservable();
  currentDisplay = this.displaySource.asObservable();
  currentParams = this.paramsSource.asObservable();
  // Quick tip: It’s an anti-pattern to expose your subject to the outside world,
  // always expose the observable, by using the asObservable() method.

  constructor(private logger: NGXLogger, private translate: TranslateService) {}

  changeDisplay(isIt: boolean) {
    this.displaySource.next(isIt);
  }

  changeType(type: AlertType) {
    this.typeSource.next(type);
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changeParams(params: AlertParameters) {
    this.paramsSource.next(params);
  }

  /**
   * Reinitialize notification (ex. when component is destroyed on navigation).
   */
  setToDefault() {
    this.changeDisplay(this.displayDefault);
    this.changeMessage(this.messageDefault);
    this.changeType(this.typeDefault);
    this.changeParams(this.paramsDefault);
  }

  /**
   * Display alert to the user & scroll up to the top so he sees it.
   * Catch all common Backend errors by code.
   */
  showAlert(
    content: string | HttpErrorResponse,
    type: AlertType,
    alertParams: AlertParameters
  ) {
    this.logger.log(content);
    let message = this.messageDefault;
    if (typeof content === 'string') {
      message = content;

      // Custom code error from Backend
    } else if (content?.error?.code) {
      message = this.translate.instant('error.' + content.error.code);

      // All BE errors
    } else if (content instanceof HttpErrorResponse && content.status >= 400) {
      this.logger.log('in error');
      if (
        content.status === 400 ||
        content.status === 405 ||
        content.status === 406 ||
        content.status === 407 ||
        content.status === 415
      ) {
        // 405: method Not allowed; 406 Not Acceptable; 407: Proxy error; 415 Unsupported Media Type
        // message = `Request error. Please contact an administrator.`;
        message = `Erreur de requête. Veuillez contacter un administrateur.`;
      } else if (content.status === 401) {
        // message = `Access denied: unauthorized. \n
        //           Try reloading the page or restart the browser and log back into application.`;
        message = `Accès refusé: non-autorisé. \n
                  Essayez de recharger la page ou relancer le navigateur et vous reconnecter à l'application`;
      } else if (content.status === 403) {
        // message = `Access denied: unsufficient rights. You don't have access to this resource.`;
        message = `Access refusé: droits insuffisants. Vous n'avez pas accès à cette ressource.`;
      } else if (content.status === 404 || content.status === 410) {
        // message = `Not found. The resource you requested have never existed or has been deleted.`;
        message = `Non trouvé. La ressource à laquelle vous voulez accéder n'éxiste pas ou a été supprimée.`;
      } else if (content.status === 408) {
        // message = `Request timeout. Check your internet connexion or try again later.`;
        message = `Le délai d'attente de réponse est dépassé. Vérifiez votre connexion internet ou réessayez plus tard.`;
      } else if (content.status === 409) {
        // TODO: i18n
        message = `Conflit: ${content.error.details}.`; // keep message as user is supposed to resolve the conflict
      } else if (content.status === 429) {
        message = `Vous avez fait trop de requêtes. Merci de patienter un peu avant de faire une nouvelle requête.`;
        // message = `You have made too many requests. Please wait a little while before making a new request.`;
      } else if (content.status >= 500) {
        if (!!content.error && content.error.details) {
          if (content.error.code === 'NODATAFOUND') {
            message = 'Élément non trouvé. ';
          }
          if (content.error.details) {
            message =
              'Erreur interne. Si le problème persiste, veuillez contacter un administrateur.';
            // message += content.error.details; TODO: quand les messages vont être internationalisés
            this.logger.log(message, content.error.details);
          }
        } else {
          // message = `Server error.`;
          message = `Erreur serveur.`;
        }
      } else {
        // message = `Something went wrong. Please try again later.`;
        message = `Une erreur s'est produite. Merci de réessayer plus tard.`;
      }
    }

    this.changeMessage(message);
    this.changeType(type);
    this.changeParams(alertParams);
    this.changeDisplay(true);
  }
}
