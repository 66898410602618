/* tslint:disable */

/**
 * field to filter for subcription report search:
 *   * DIFFUSION_LIST_NAME : the diffusion list's name
 *   * REPORT_TYPE : the report's name
 */
type SubscriptionReportSearchFilter =
  'DIFFUSION_LIST_NAME' |
  'REPORT_TYPE';
module SubscriptionReportSearchFilter {
  export const DIFFUSION_LIST_NAME: SubscriptionReportSearchFilter = 'DIFFUSION_LIST_NAME';
  export const REPORT_TYPE: SubscriptionReportSearchFilter = 'REPORT_TYPE';
  export function values(): SubscriptionReportSearchFilter[] {
    return [
      DIFFUSION_LIST_NAME,
      REPORT_TYPE
    ];
  }
}

export { SubscriptionReportSearchFilter }