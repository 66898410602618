import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserSessionService } from 'src/app/tinea-components/okta/services/session/user-session.service';
import { ConsumeDataService } from 'src/app/tinea-components/okta/services/consume-data.service';

@Injectable()
export class ConsumeDataTokenInterceptor implements HttpInterceptor {

  request: HttpRequest<any>;

  constructor(public userSessionService: UserSessionService,
              public consumeDataService: ConsumeDataService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.request = request;

    const headers = this.getHeaders();
    request = request.clone({
      setHeaders: headers
    });


    return next.handle(request);
  }

  /**
   * Get headers object
   */
  getHeaders(): any {
    const dataheader = this.consumeDataService.getHeaderForConsumeData(this.request);
    return dataheader;
  }
}
