import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const slideInLeftToRightAnimation = trigger('slideInLeftToRight', [
  state('void', style({ transform: 'translateX(-100%)' })),
  transition('void => *', [
    animate(200, style({ transform: 'translateX(0)' }))
  ]),
  transition('* => void', [
    animate(200, style({ transform: 'translateX(-100%)' }))
  ])
]);
