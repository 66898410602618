import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { DisplayErrorsMessageComponent } from './display-errors-message.component';

@NgModule({
  declarations: [DisplayErrorsMessageComponent],
  imports: [CommonModule, TranslateModule],
  exports: [DisplayErrorsMessageComponent],
})
export class DisplayErrorsMessageModule {}
