import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AxwayService {

    GET_TOKEN_BODY_PARAMS = JSON.parse(environment.getTokenBodyParams);
    GET_TOKEN_CONTENT_TYPE = 'application/x-www-form-urlencoded';

    constructor(private http: HttpClient) { }

    public postToken(token: string): Observable<any> {

        let bodyParams = '';
        this.GET_TOKEN_BODY_PARAMS.forEach((value, i: number) => {
            let separator = '';
            if (i > 0) {
                separator = '&';
            }
            if (value === 'assertion') {
                bodyParams += separator + value + '=' + token;
            } else {
                bodyParams += separator + value;
            }
        });

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': this.GET_TOKEN_CONTENT_TYPE
            })
        };

        return this.http
        .post<any>(environment.tineaTokenUrl, bodyParams, httpOptions)
        .pipe(
            catchError(this.handleTineaError)
        );
    }

    handleTineaError(error: any | any) {
        return throwError(error.error || error || 'Getting tinea token error.');
      }
}
