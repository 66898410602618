<div class="side-bar__ctn" [class.minimised]="!fullSidebar">
    <div class="left-navigation-logo">
        <a routerLink="/">
            <img [src]="logo" class="logo-hydrowatt" [class.d-none]="fullSidebar" alt="Logo">
            <img [src]='logoInline' class="logo-hydrowatt" [class.d-none]="!fullSidebar" alt="Logo">
        </a>
    </div>
    <div class="left-navigation-menu">
        <ul class="list-menu">
            <li *ngFor="let route of routes">
                <a [routerLink]="'/' + route.route" [queryParams]="route.params" [routerLinkActive]="['active']">
                    <i [class]="route.icon + ' icon-menu'"></i>
                    <span class="item-menu text-menu" [translate]="route.name"></span>
                </a>
            </li>

            <li *ngIf="autAppGuard !== NotAuthenticatedJustLoggedIn">
                <a href="javascript:void(0)" (click)="logout()">
                    <i [class]="iconClass.LOG_OUT + ' icon-menu'"></i>
                    <span class="item-menu text-menu" [translate]="'menu.logout'"></span>
                </a>
            </li>
        </ul>
    </div>

    <div *ngIf="autAppGuard !== NotAuthenticatedJustLoggedIn" class="left-navigation-second">
        <i class="fas fa-user-circle icon-menu-profile"></i>
        <span class="item-menu">{{userName}}</span>
    </div>

    <a *ngIf="publicSiteDisplay" class="left-navigation-second" [href]="urlPublicWwebsite" target="blank">
        <i class="fas fa-desktop icon-menu-profile"></i>
        <span class="item-menu "[translate]="'menu.publicWebsite'"></span>
    </a>
</div>