/* tslint:disable */

/**
 * field to filter for role search:
 *   * NAME : the role name
 */
type RoleSearchFilter =
  'NAME';
module RoleSearchFilter {
  export const NAME: RoleSearchFilter = 'NAME';
  export function values(): RoleSearchFilter[] {
    return [
      NAME
    ];
  }
}

export { RoleSearchFilter }