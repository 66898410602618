/* tslint:disable */

/**
 * The type of security policy:
 *   * BASIC128RSA15
 *   * BASIC256
 *   * BASIC256SHA256
 *   * AES128SHA256RSAOAEP
 *   * AES256SHA256RSAPSS
 */
type SecurityPolicy =
  'BASIC128RSA15' |
  'BASIC256' |
  'BASIC256SHA256' |
  'AES128SHA256RSAOAEP' |
  'AES256SHA256RSAPSS';
module SecurityPolicy {
  export const BASIC128RSA15: SecurityPolicy = 'BASIC128RSA15';
  export const BASIC256: SecurityPolicy = 'BASIC256';
  export const BASIC256SHA256: SecurityPolicy = 'BASIC256SHA256';
  export const AES128SHA256RSAOAEP: SecurityPolicy = 'AES128SHA256RSAOAEP';
  export const AES256SHA256RSAPSS: SecurityPolicy = 'AES256SHA256RSAPSS';
  export function values(): SecurityPolicy[] {
    return [
      BASIC128RSA15,
      BASIC256,
      BASIC256SHA256,
      AES128SHA256RSAOAEP,
      AES256SHA256RSAPSS
    ];
  }
}

export { SecurityPolicy }