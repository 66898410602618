/* tslint:disable */

/**
 * field to filter for alarm search:
 *   * ALL : All fields
 *   * NAME : alarm name/alias
 *   * DESCRIPTION : alarm description
 *   * STATION : station name
 */
type AlarmSearchFilter =
  'ALL' |
  'NAME' |
  'DESCRIPTION' |
  'STATION';
module AlarmSearchFilter {
  export const ALL: AlarmSearchFilter = 'ALL';
  export const NAME: AlarmSearchFilter = 'NAME';
  export const DESCRIPTION: AlarmSearchFilter = 'DESCRIPTION';
  export const STATION: AlarmSearchFilter = 'STATION';
  export function values(): AlarmSearchFilter[] {
    return [
      ALL,
      NAME,
      DESCRIPTION,
      STATION
    ];
  }
}

export { AlarmSearchFilter }