/* tslint:disable */

/**
 * report type:
 *   * CLIPRO
 *   * PGCSV
 *   * PGXLS
 *   * GNVERT
 */
type ReportType =
  'CLIPRO' |
  'PGCSV' |
  'PGXLS' |
  'GNVERT';
module ReportType {
  export const CLIPRO: ReportType = 'CLIPRO';
  export const PGCSV: ReportType = 'PGCSV';
  export const PGXLS: ReportType = 'PGXLS';
  export const GNVERT: ReportType = 'GNVERT';
  export function values(): ReportType[] {
    return [
      CLIPRO,
      PGCSV,
      PGXLS,
      GNVERT
    ];
  }
}

export { ReportType }