import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseFeatureGuard } from 'src/app/models/BaseFeatureGuard';
import { RightService } from 'src/app/services/right.service';
import { ApplicationFeature } from 'src/swagger-gen/web/models';


@Injectable({
  providedIn: 'root'
})
export class VehicleGuard extends BaseFeatureGuard implements CanActivate {

  state: RouterStateSnapshot;
  route: ActivatedRouteSnapshot;
  isRefreshPage = true;

  constructor(public router: Router,
              protected rightService: RightService) {
    super(rightService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.state = state;
      this.route = route;
      
      return this.handler(ApplicationFeature.VEHICLE);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
