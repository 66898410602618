
<!-- <div class="wrap-alert"> -->
  <div class="content " [ngSwitch]="type$ | async">
    <div *ngSwitchCase="notificationType.ERROR" class="alert-error">
      <div class="alert-header" [ngClass]="{ withTitle: !!(parameters$ | async).title }">

      
        <i class="icon-alert-triangle"></i>
        <h2>{{ (parameters$ | async).title }}</h2>
    
        <button type="button" class="close-alert icon-x-circle1" aria-label="Close" (click)="closeAlertBand()"
          *ngIf="(parameters$ | async).withCloseButton">
        </button>
      </div>

      <div class="alert-content">
        <div class="alert-icon-container" *ngIf="!!(parameters$ | async).icon">
          <i [ngClass]="'icon-' + (parameters$ | async).icon"></i>
        </div>
        <p class="alert-message">{{ message$ | async }}</p>
        <!-- <div>
            Besoin d'aide ?
            <a [routerLink]="['/contact']" routerLinkActive="router-link-active"
              >contacter nous</a
            ></div>  -->
      </div>

      <div class="alert-action" *ngIf="!!action">
        <button (click)="getAction()">{{ action.text }}</button>
      </div>
    </div>
<!-- INFO -->
<div *ngSwitchCase="notificationType.INFO" class="alert-info ">
  <div class="alert-header" [ngClass]="{ withTitle: !!(parameters$ | async).title }">
    <i class="icon-info1"></i>
    <h2>{{ (parameters$ | async).title }}</h2>

    <button type="button" class="close-alert icon-x-circle1" aria-label="Close" (click)="closeAlertBand()"
      *ngIf="(parameters$ | async).withCloseButton">
      
    </button>
  </div>

  <div class="alert-content">
    <p class="alert-icon-container" *ngIf="!!(parameters$ | async).icon">
      <i [ngClass]="'icon-' + (parameters$ | async).icon"></i>
    </p>
    <p class="alert-message">{{ message$ | async }}</p>
    <!-- <span>
        Besoin d'aide ?
        <a [routerLink]="['/contact']" routerLinkActive="router-link-active"
          >contacter nous</a
        ></span> -->
  </div>

  <div class="alert-action" *ngIf="!!action">
    <button (click)="getAction()">{{ action.text }}</button>
  </div>
</div>
    <!-- WARNING -->

    <div *ngSwitchCase="notificationType.WARNING" class="alert-warning ">
      <div class="alert-header" [ngClass]="{ withTitle: !!(parameters$ | async).title }">
        <i class="icon-warning-circle"></i>
        <h2>{{ (parameters$ | async).title }}</h2>

        <button type="button" class="close-alert icon-x-circle1" aria-label="Close" (click)="closeAlertBand()"
          *ngIf="(parameters$ | async).withCloseButton">
        </button>
      </div>

      <div class="alert-content">
          <span class="alert-icon-container" *ngIf="!!(parameters$ | async).icon"><i [ngClass]="'icon-' + (parameters$ | async).icon"></i></span>
          <span class="alert-message" [innerHTML]="(message$ | async)"></span> <br>
          <!-- <span> Need help? You may <a [routerLink]="['/contact']" routerLinkActive="router-link-active">contact us</a></span> -->
        </div>
        <form class="input-form" #inputForm="ngForm" (ngSubmit)="getAction()">
          <input type="text" [(ngModel)]="inputValue" *ngIf="(parameters$ | async)?.withInput" pattern="\d{4,}"
                  name="inputValue" minlength="4" (keyup.enter)="getAction()" required>
          <span class="error" *ngIf="(parameters$ | async)?.withInput && showInputErrorMessage">Code incorrect</span>
        </form>
        <div class="alert-action" *ngIf="!!action">
          <!-- DEBUG -->
          <!-- <span>{{inputForm?.invalid}}</span> -->
          <button (click)="getAction()" [disabled]="(parameters$ | async)?.withInput && inputForm?.invalid">{{action.text}}</button>
      </div>

      <div class="alert-action" *ngIf="!!action">
        <button (click)="getAction()">{{ action.text }}</button>
      </div>
    </div>

  
  </div>
<!-- </div> -->