import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { coreEnvironment } from 'src/environments/core/core.environment';
import { environment } from 'src/environments/environment';

import { AlertService } from './services';
import { Globals } from './shared/configuration/globals';
import { FullyLoggedInStateType } from './tinea-components/okta/enum/FullyLoggedInStateType';
import { LoggedInService } from './tinea-components/okta/services/loggedIn.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  previousUrl: string;
  currentUrlSlug: string;
  isloggedIn = false;
  fullSidebar = false;
  route: string;
  maintenance = coreEnvironment.maintenance;

  displayNotification$: Observable<boolean> = of(false);
  show = true;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private titleService: Title,
    private loggedInService: LoggedInService,
    private translate: TranslateService,
    private globals: Globals,
    private alertService: AlertService
  ) {
    // subscribe to alert observable &
    // simulate delaying it's execution in case we need to show the message right on application start
    this.displayNotification$ = this.alertService.currentDisplay.pipe(delay(0));

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.route = e.url;
      }
    });

    const languageStored = localStorage.getItem('language');

    if (languageStored) {
      this.translate.use(languageStored);
    } else {
      /*  If browser language not in the list, use default language. */
      const browserLanguage = this.translate.getBrowserLang();
      if (
        this.globals.languages.find((lang) => lang.name === browserLanguage) ===
        undefined
      ) {
        this.translate.use(this.translate.getDefaultLang());
        localStorage.setItem('language', this.translate.getDefaultLang());
      } else {
        this.translate.use(browserLanguage);
        localStorage.setItem('language', browserLanguage);
      }
    }

    // get url & set body class depending on space
    this.setBodyClass();
  }

  ngOnInit() {
    this.isLoggedinPropertyObserve();
  }

  ngAfterViewInit() {}

  async isLoggedinPropertyObserve() {
    // set property 'isLoggedin' on refresh page
    const isLoggedIn = await this.loggedInService.isLoggedIn();
    this.setLoggedInProperty(isLoggedIn);
    // subscribe to observer
    this.loggedInService.fullyLoggedInState$.subscribe(
      (isFullyLoggedIn: FullyLoggedInStateType) => {
        if (isFullyLoggedIn === FullyLoggedInStateType.SUCCESS) {
          this.setLoggedInProperty(true);
        } else {
          this.setLoggedInProperty(false);
        }
      }
    );
  }

  setLoggedInProperty(value: boolean) {
    this.isloggedIn = value;
  }

  setBodyClass() {
    // take event that contains the final route,
    // otherwise irrelevant url slugs like type errors will become body class
    this.router.events
      .pipe(filter((e) => e instanceof RoutesRecognized))
      .subscribe((event: RoutesRecognized) => {
        if (this.previousUrl && this.previousUrl !== '/') {
          this.renderer.removeClass(
            document.body,
            this.previousUrl.split('/')[1]
          );
        }
        this.currentUrlSlug = event.urlAfterRedirects.split('/')[1];
        if (this.currentUrlSlug === '') {
          this.renderer.addClass(document.body, 'home');
          this.fullSidebar = true;
        }

        if (this.currentUrlSlug) {
          this.renderer.removeClass(document.body, 'home');
          this.renderer.addClass(document.body, this.currentUrlSlug);
          this.fullSidebar = false;
          // this.logger.log('Current url slug ', this.currentUrlSlug); // DEBUG
        }

        // set url for redirecting user after login (TODO: currently, avoiding sites list, to be changed when several sites view available)
        this.previousUrl = event.urlAfterRedirects;
        if (
          !this.previousUrl.includes('login') &&
          !this.previousUrl.includes('implicit/callback') &&
          this.previousUrl !== '/sites'
        ) {
          // this.logger.log('set previous url', this.globals.previousUrl); // DEBUG
        }
      });

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setTitle(this.currentUrlSlug);
      });
  }

  /**
   * Set the relevant title for each page, that will be visible in history.
   */
  public setTitle(newTitle: string) {
    let formattedTitle: string = newTitle;
    if (formattedTitle.includes('?')) {
      formattedTitle = formattedTitle.split('?')[0];
    }

    const translatedTitle: string = this.translate.instant(
      `menu.${formattedTitle}`
    );

    let fullNewTitle = environment.core.title;
    if (!!translatedTitle && !translatedTitle.includes('menu.')) {
      fullNewTitle = environment.core.title + ' - ' + translatedTitle;
    }

    this.titleService.setTitle(fullNewTitle);
  }
}
