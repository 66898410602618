import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CustomOktaService } from '../../services/custom-okta.service';
import { UserSessionService } from '../../services/session/user-session.service';
import { environment } from 'src/environments/environment';
import { TokenExpirationService } from '../../services/tokenExpiration.service';
import { LoggedInService } from '../../services/loggedIn.service';


@Injectable({
  providedIn: 'root'
})
export class CustomOktaAuthGuard implements CanActivate {

  state: RouterStateSnapshot;
  route: ActivatedRouteSnapshot;

  constructor(private customOktaService: CustomOktaService,
              private userSessionService: UserSessionService,
              private tokenExpirationService: TokenExpirationService,
              private loggedInService: LoggedInService) {
  }

  /**
   * Must have access token
   * Must have valid expiration
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

      this.state = state;
      this.route = route;

      return new Promise<boolean>(async (resolve, reject) => {
        const accessToken = this.customOktaService.getToken();
        const isExpired = await this.isExpired();
        if (accessToken && !isExpired) {
            resolve(true);
        } else {
          // set route in localstorage
          const redirectTo = (this.state.url) ? this.state.url : environment.authPathRedirectAfterLogin;
          this.userSessionService.setRedirectTo(redirectTo);
          await this.customOktaService.redirectToIam();
          resolve(false);
        }
    });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

  async isExpired(): Promise<boolean> {
    await this.loggedInService.authTokenExpireAtHandler();
    const isExpired = this.tokenExpirationService.authTokenExpired();
    return isExpired;
  }
}
