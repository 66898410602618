/* tslint:disable */

/**
 * The alarm state:
 *   * RAISED: the alarm has been raised and is still active
 *   * RAISED_ACKNOWLEDGED: the alarm has been raised and is already acknowledged
 *   * RESETED: raised alarm has been just reseted
 *   * RESETED_ACKNOWLEDGED: raised alarm has been acknowledged and reseted
 *   * INHIBITED: raised alarm has been inhibited
 */
type AlarmState =
  'RAISED' |
  'RAISED_ACKNOWLEDGED' |
  'RESETED' |
  'RESETED_ACKNOWLEDGED' |
  'INHIBITED';
module AlarmState {
  export const RAISED: AlarmState = 'RAISED';
  export const RAISED_ACKNOWLEDGED: AlarmState = 'RAISED_ACKNOWLEDGED';
  export const RESETED: AlarmState = 'RESETED';
  export const RESETED_ACKNOWLEDGED: AlarmState = 'RESETED_ACKNOWLEDGED';
  export const INHIBITED: AlarmState = 'INHIBITED';
  export function values(): AlarmState[] {
    return [
      RAISED,
      RAISED_ACKNOWLEDGED,
      RESETED,
      RESETED_ACKNOWLEDGED,
      INHIBITED
    ];
  }
}

export { AlarmState }