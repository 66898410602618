export class AlertAction {
  text: string; // text that is displayed on button
  name: string; // method name
  parameters?: any[];

  constructor($text: string, $name: string, $parameters?: any[]) {
    try {
      if ($text.trim().length === 0) {
        throw new Error('Text must not be empty');
      } else if ($text.length > 22) {
        throw new Error('Text must not be longer than 22 chars.');
      } else {
        this.text = $text;
      }

      this.name = $name;

      if (!!$parameters) {
        this.parameters = $parameters;
      }
    } catch (error) {
      // console.log('Error in AlertAction constructor:', error);
    }
  }
}
