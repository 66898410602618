<section id="loggedin-wrap">
    <div class="loggedin-wrap-container">
        <div *ngIf="isFullyLoggedIn === fullyLoggedInStateType.SUCCESS">
        </div>

        <div class="container-error" *ngIf="isFullyLoggedIn ===  fullyLoggedInStateType.NO_FULLY_LOGGED_IN">
            <div>
                <div class="okta-custom-message py-20">
                    {{'login.errors.message' | translate}}
                </div>
                <div class="text-center">
                    <button class="btn btn--primary mr-10" (click)="retry()">{{'login.errors.retry' | translate}}</button>
                    <button class="btn btn--outline-secondary"
                        (click)="reconnect()">{{'login.errors.resign_in' | translate}}</button>
                </div>
            </div>
        </div>

        <div *ngIf="isFullyLoggedIn == fullyLoggedInStateType.PENDING">
        </div>
    </div>
</section>
