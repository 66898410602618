/* tslint:disable */

/**
 * field to filter for vehicles search:
 *   * ALL : All fields (TYPE, ENERGY, PLATE_NUMBER)
 *   * TYPE : the type field
 *   * ENERGY : The energy field
 *   * PLATE_NUMBER : The plate number field
 *   * PARK_NUMBER : The park number field
 *   * BADGE : Badge informations
 *   * SUBSCRIPTION : subscription name
 */
type VehicleSearchFilter =
  'ALL' |
  'TYPE' |
  'ENERGY' |
  'PLATE_NUMBER' |
  'PARK_NUMBER' |
  'BADGE' |
  'SUBSCRIPTION';
module VehicleSearchFilter {
  export const ALL: VehicleSearchFilter = 'ALL';
  export const TYPE: VehicleSearchFilter = 'TYPE';
  export const ENERGY: VehicleSearchFilter = 'ENERGY';
  export const PLATE_NUMBER: VehicleSearchFilter = 'PLATE_NUMBER';
  export const PARK_NUMBER: VehicleSearchFilter = 'PARK_NUMBER';
  export const BADGE: VehicleSearchFilter = 'BADGE';
  export const SUBSCRIPTION: VehicleSearchFilter = 'SUBSCRIPTION';
  export function values(): VehicleSearchFilter[] {
    return [
      ALL,
      TYPE,
      ENERGY,
      PLATE_NUMBER,
      PARK_NUMBER,
      BADGE,
      SUBSCRIPTION
    ];
  }
}

export { VehicleSearchFilter }