import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AlarmRepo } from 'src/app/repositories/alarm-repo';
import { AlertService } from 'src/app/services';
import { Globals } from 'src/app/shared/configuration/globals';
import { AlertAction, AlertParameters, AlertType } from 'src/app/shared/models/models-index';
import { AuthService } from 'src/app/tinea-components/okta/auth/auth.service';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {

  message$: Observable<string>;
  type$: Observable<AlertType>;
  parameters$: Observable<AlertParameters>;
  notificationType = AlertType;
  action: AlertAction;
  persistant: boolean;
  inputValue = '';
  showInputErrorMessage = false;
  gestionError = true;
  error = this.notificationType.ERROR;
  warning = this.notificationType.WARNING;
  info = this.notificationType.INFO;
  private subscriptions: Subscription[] = [];

  constructor(private logger: NGXLogger,
              private alertService: AlertService,
              public router: Router,
              public alarmService: AlarmRepo,
              private authService: AuthService,
              private globals: Globals) {
    this.message$ = this.alertService.currentMessage;
    this.type$ = this.alertService.currentType;
    this.parameters$ = this.alertService.currentParams;
   }

  ngOnInit() {
    const paramsSubscription = this.parameters$.subscribe({
      next: (params) => {
        this.logger.trace('ALERT PARAMS', params);
        if (params.withTimer === true) {
          this.logger.trace('with timer', params.timer);
          setTimeout(() => {
            this.closeAlertBand();
          }, params.timer);
        }

        if (params.withActionButton === true) {
          this.logger.trace('with action', params.action);
          this.action = params.action;
        }

        if (params.persistant === false) {
          this.logger.trace('persistant=false');
          this.persistant = false;
        }

        if (params.withInput === true) {
          this.logger.trace('withInput=true');
        }
      },
      error: (error: any) => this.logger.log(error)
    });

    this.subscriptions.push(paramsSubscription);

    /* If alert messsage is not persistant, close it right when navigation path is recognized */
    this.router.events.pipe(filter(e => e instanceof RoutesRecognized)).subscribe({
      next: (event) => {
        if (this.persistant === false) {
          this.closeAlertBand();
        }
      },
      error: (error: any) => this.logger.log(error)
    });
  }


  /**
   * Generic function used to launch relevant method based on action property.
   * action.name = method name
   * action.parameters = method params
   * @param action : action launched on click on action button
   */
  getAction() {
    this.logger.trace('this.action.name', this.action.name, 'this.action.parameters', this.action.parameters);
    this[this.action.name](this.action.parameters);
  }

  /**
   * Launch a navigation from the alert message.
   * @param url navigation url
   */
  navigateTo(url: string[]) {
    this.logger.trace('redirection to', url);
    this.router.navigate(url);
    // this.closeAlertBand(); TODO: check if should do so
  }

  closeAlertBand() {
    this.alertService.setToDefault();
    this.logger.trace('Alert dismissed');
  }

  /************************* CUSTOM ACTION METHODS **********************/
  /**
   * Check if input contains only digits & if so, send call to BE to reset alarms.
   * Otherwise display an error message.
   */
  resetAlarms() {
    this.showInputErrorMessage = false;
    this.logger.trace('input value', this.inputValue);
    const codeRegExp = new RegExp(/^[0-9]{4}$/, 'g');
    const isOk = codeRegExp.test(this.inputValue);
    this.logger.trace('isOk', isOk);
    if (isOk) {
      const alarmsSubscription = this.alarmService.resetAlarms(+this.inputValue).subscribe({
        next: (response) => {
          this.logger.log('Reset alarms OK:', response);
          this.closeAlertBand();
        },
        error: (error: any) => {
          this.logger.log('Reset alarms ERROR:', error);
          this.showInputErrorMessage = true;
        }
      });
      this.subscriptions.push(alarmsSubscription);
    } else {
      this.showInputErrorMessage = true;
    }
  }

  // NB: kept in case we want to show a message on logout error. TODO: check if still necessary
  redirectToLogin() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
    this.closeAlertBand();
  }

  ngOnDestroy(): void {
    if (this.subscriptions !== undefined) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }
}
