/* tslint:disable */

/**
 * The type of energy:
 *   * ELECTRICITY
 *   * HYDROGEN
 *   * GNL
 *   * GNC
 *   * GNCL
 *   * BIOGNC
 *   * UNDEFINED
 */
type EnergyType =
  'ELECTRICITY' |
  'HYDROGEN' |
  'GNL' |
  'GNC' |
  'GNCL' |
  'BIOGNC' |
  'UNDEFINED';
module EnergyType {
  export const ELECTRICITY: EnergyType = 'ELECTRICITY';
  export const HYDROGEN: EnergyType = 'HYDROGEN';
  export const GNL: EnergyType = 'GNL';
  export const GNC: EnergyType = 'GNC';
  export const GNCL: EnergyType = 'GNCL';
  export const BIOGNC: EnergyType = 'BIOGNC';
  export const UNDEFINED: EnergyType = 'UNDEFINED';
  export function values(): EnergyType[] {
    return [
      ELECTRICITY,
      HYDROGEN,
      GNL,
      GNC,
      GNCL,
      BIOGNC,
      UNDEFINED
    ];
  }
}

export { EnergyType }