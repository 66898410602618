/* tslint:disable */

/**
 * The cost unit:
 *   * EUR
 */
type CostUnit =
  'EUR';
module CostUnit {
  export const EUR: CostUnit = 'EUR';
  export function values(): CostUnit[] {
    return [
      EUR
    ];
  }
}

export { CostUnit }