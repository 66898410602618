/* tslint:disable */

/**
 * The volume unit:
 *   * KG
 *   * L
 *   * G
 *   * M3
 */
type VolumeUnit =
  'KG' |
  'L' |
  'G' |
  'M3';
module VolumeUnit {
  export const KG: VolumeUnit = 'KG';
  export const L: VolumeUnit = 'L';
  export const G: VolumeUnit = 'G';
  export const M3: VolumeUnit = 'M3';
  export function values(): VolumeUnit[] {
    return [
      KG,
      L,
      G,
      M3
    ];
  }
}

export { VolumeUnit }