<div *ngIf="maintenance" class="maintenance-container">
  <div class="maintenance-message-container">
    <div class="maintenance-title">
      {{'maintenance.title' | translate}}
    </div>
    <div class="maintenance-message">
      {{'maintenance.message' | translate}}
    </div>
  </div>
</div>
<div *ngIf="!maintenance" id="global" class="global-container">
    <div  *ngIf="isloggedIn">
        <div class="app-left-navigation" (mouseover)="fullSidebar = true" (mouseout)="fullSidebar = false" [class.extended]="fullSidebar" [fullSidebar]="fullSidebar"></div>
    </div>
    <div *ngIf="isloggedIn" [ngClass]="!fullSidebar ? 'right-content--extended' : 'right-content'">
        <router-outlet></router-outlet>
        <div id="wrap-main" class="global-subelement-container"
          *ngIf="currentUrlSlug === 'login' || currentUrlSlug === 'implicit' || currentUrlSlug === 'error-page' || currentUrlSlug === 'logged-in-callback'">
          <!-- Routed components go here, all hidden when user is not authenticated except the login page-->
          <div id="main">
            <div id="main-content">
              
            </div>
          </div>    
        </div>
    </div>
    <div class="notLogged">
        <router-outlet></router-outlet>
    </div>
    <div id="alert-container" *ngIf="displayNotification$ | async" [ngClass]="(displayNotification$ | async) ? 'show-alert': 'alert-hidden'">
      <app-alert></app-alert>
    </div>
    <div id="alert-overlay" *ngIf="displayNotification$ | async"></div>
</div>

