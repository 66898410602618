/* tslint:disable */

/**
 * field to filter for stations search:
 *   * ALL : All fields
 *   * NAME : the station's name
 */
type GNLSearchFilter =
  'ALL' |
  'NAME';
module GNLSearchFilter {
  export const ALL: GNLSearchFilter = 'ALL';
  export const NAME: GNLSearchFilter = 'NAME';
  export function values(): GNLSearchFilter[] {
    return [
      ALL,
      NAME
    ];
  }
}

export { GNLSearchFilter }