import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimePickerComponent } from './component/dateTime-picker.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DateTimePickerComponent],
  imports: [
    CommonModule,
    DpDatePickerModule,
    FormsModule,
    TranslateModule
  ],
  exports: [DateTimePickerComponent]
})
export class DateTimePickerStaticModule { }
