import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableRowRoutingModule } from './table-row-routing.module';
import { EventComponent } from './event/event.component';
import { AlarmComponent } from './alarm/alarm.component';
import { EventGroupComponent } from './event-group/event-group.component';
import { TagGroupComponent } from './tag-group/tag-group.component';
import { TagComponent } from './tag/tag.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    EventComponent,
    AlarmComponent,
    EventGroupComponent,
    TagGroupComponent,
    TagComponent
  ],
  imports: [
    CommonModule,
    TableRowRoutingModule,
    TranslateModule
  ],
  exports: [
    EventComponent,
    AlarmComponent,
    EventGroupComponent,
    TagGroupComponent,
    TagComponent
  ]
})
export class TableRowModule { }
