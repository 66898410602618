import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { MqttModule } from 'ngx-mqtt';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreRoutingModule,
  ],
  exports: [
  ],
})
export class CoreModule {
  /* Only the root AppModule should import the CoreModule.
  To guard against a lazy-loaded module re-importing CoreModule: */
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
 }
