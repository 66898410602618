/* tslint:disable */

/**
 * field to filter for tag search:
 *   * ALL : All fields (NAME)
 *   * NAME : the name field
 */
type TagSearchFilter =
  'ALL' |
  'NAME';
module TagSearchFilter {
  export const ALL: TagSearchFilter = 'ALL';
  export const NAME: TagSearchFilter = 'NAME';
  export function values(): TagSearchFilter[] {
    return [
      ALL,
      NAME
    ];
  }
}

export { TagSearchFilter }