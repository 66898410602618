/* tslint:disable */

/**
 * The provider of badge:
 *   * LAFON
 *   * GIR
 *   * GIR_GNV
 *   * GIR_LAFON
 */
type BadgeProvider =
  'LAFON' |
  'GIR' |
  'GIR_GNV' |
  'GIR_LAFON';
module BadgeProvider {
  export const LAFON: BadgeProvider = 'LAFON';
  export const GIR: BadgeProvider = 'GIR';
  export const GIR_GNV: BadgeProvider = 'GIR_GNV';
  export const GIR_LAFON: BadgeProvider = 'GIR_LAFON';
  export function values(): BadgeProvider[] {
    return [
      LAFON,
      GIR,
      GIR_GNV,
      GIR_LAFON
    ];
  }
}

export { BadgeProvider }