import { ErrorMessageDirection } from '../enums/ErrorMessageDirection';

export class ErrorMessageStyleModel {

  readonly DEFAULT_BACKGROUND_COLOR = 'var(--negative-color)';
  readonly DEFAULT_BORDER_RADIUS = '3px';
  readonly DEFAULT_TRANSITION = 'none';
  readonly DEFAULT_PADDING = '15px';
  readonly DEFAULT_POPUP_TOP = '10%';
  readonly DEFAULT_POPUP_ZINDEX = 10000;


  private type: 'static' | 'popup';
  private direction: ErrorMessageDirection;

  position: 'fixed' | 'static';
  left: string;
  right: string;
  top: string;
  backgroundColor: string;
  zIndex: number;
  padding: string;
  borderRadius: string;
  transition: string;
  display = null;

  constructor(type: 'static' | 'popup', direction: ErrorMessageDirection) {
    this.type = type;
    this.direction = direction;
    this.getPosition();
    this.getLeft();
    this.getRight();
    this.getTop();
    this.getZindex();

    this.padding = this.DEFAULT_PADDING;
    this.backgroundColor = this.DEFAULT_BACKGROUND_COLOR;
    this.borderRadius = this.DEFAULT_BORDER_RADIUS;
    this.transition = this.DEFAULT_TRANSITION;
  }

  getPosition(): void {
    switch (this.type) {
      case 'popup':
        this.position = 'fixed';
        break;
      default:
        break;
    }
  }

  getLeft(): void {
    switch (this.type) {
      case 'popup':
        this.getLeftIfPopup(false);
        break;
      default:
        break;
    }
  }

  getRight(): void {
    switch (this.type) {
      case 'popup':
        this.getRightIfPopup(false);
        break;
      default:
        break;
    }
  }

  getRightIfPopup(show: boolean): void {
    switch (this.direction) {
      case ErrorMessageDirection.BOTTOM_TOP:
        this.right = null;
        break;
      case ErrorMessageDirection.LEFT_RIGHT:
        this.right = null;
        break;
      case ErrorMessageDirection.NONE:
        this.right = null;
        break;
      case ErrorMessageDirection.RIGHT_LEFT:
        this.right = show ? '0%' : '-100%';
        break;
      case ErrorMessageDirection.TOP_BOTTOM:
        this.right = null;
        break;
      default:
        break;
    }
  }

  getLeftIfPopup(show: boolean): void {
    switch (this.direction) {
      case ErrorMessageDirection.BOTTOM_TOP:
        this.left = null;
        break;
      case ErrorMessageDirection.LEFT_RIGHT:
        this.left = show ? '0%' : '-100%';
        break;
      case ErrorMessageDirection.NONE:
        this.left = null;
        break;
      case ErrorMessageDirection.RIGHT_LEFT:
        this.left = null;
        break;
      case ErrorMessageDirection.TOP_BOTTOM:
        this.left = null;
        break;
      default:
        break;
    }
  }

  getTop(): void {
    switch (this.type) {
      case 'popup':
        this.top = this.DEFAULT_POPUP_TOP;
        break;
      default:
        break;
    }
  }

  getZindex(): void {
    switch (this.type) {
      case 'popup':
        this.zIndex = this.DEFAULT_POPUP_ZINDEX;
        break;
      default:
        break;
    }
  }

  hide(): this {
    switch (this.type) {
      case 'popup':
        this.getRightIfPopup(false);
        this.getLeftIfPopup(false);
        break;
      case 'static':
        this.display = 'none';
        break;
    }
    return this;
  }

  show(): this {
    switch (this.type) {
      case 'popup':
        this.getRightIfPopup(true);
        this.getLeftIfPopup(true);
        break;
      case 'static':
        this.display = null;
        break;
    }
    return this;
  }
}
