/* tslint:disable */

/**
 * type of diffusion:
 *   * STANDARD
 *   * TICKETING
 */
type DiffusionListType =
  'STANDARD' |
  'TICKETING';
module DiffusionListType {
  export const STANDARD: DiffusionListType = 'STANDARD';
  export const TICKETING: DiffusionListType = 'TICKETING';
  export function values(): DiffusionListType[] {
    return [
      STANDARD,
      TICKETING
    ];
  }
}

export { DiffusionListType }