/**
 * les valeurs correspondent à l'options "style" https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 * Pour des quetsions de perfs on utilise ces valeurs pour économiser en mapping
 * */
export enum StyleNumber {
  DECIMAL = 'decimal',
  CURRENCY = 'currency',
  PERCENT = 'percent',
  DURATION = 'duration'
}
