import { UiIcon } from '../../table/models/input/ui-icon';
import { DisplayText } from 'src/app/models/events/displayText';
import { LoadingTypeEnum } from '../enum/loadingType.enum';

export class UiLoadingModel {
  isLoading: boolean;
  icon?: UiIcon;
  text?: DisplayText;
  cssClassText: string;
  type: LoadingTypeEnum;

  constructor(type: LoadingTypeEnum = LoadingTypeEnum.DEFAULT) {
    this.isLoading = false;
    this.icon = null;
    this.text = null;
    this.cssClassText = null;
    this.type = type;
  }
}
