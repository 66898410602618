/* tslint:disable */

/**
 * The category of energy:
 *   * ELECTRICITY
 *   * HYDROGEN
 *   * GAS
 *   * UNDEFINED
 */
type EnergyCategory =
  'ELECTRICITY' |
  'HYDROGEN' |
  'GAS' |
  'UNDEFINED';
module EnergyCategory {
  export const ELECTRICITY: EnergyCategory = 'ELECTRICITY';
  export const HYDROGEN: EnergyCategory = 'HYDROGEN';
  export const GAS: EnergyCategory = 'GAS';
  export const UNDEFINED: EnergyCategory = 'UNDEFINED';
  export function values(): EnergyCategory[] {
    return [
      ELECTRICITY,
      HYDROGEN,
      GAS,
      UNDEFINED
    ];
  }
}

export { EnergyCategory }