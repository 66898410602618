/* tslint:disable */

/**
 * field to filter for ticket search:
 *   * ALL : All fields
 *   * ID_TICKET
 *   * STATION_NAME
 *   * TYPE_PANNE
 *   * EQUIPEMENT
 *   * DESCRIPTION
 *   * STATE
 */
type TicketSearchFilter =
  'ALL' |
  'ID_TICKET' |
  'STATION_NAME' |
  'TYPE_PANNE' |
  'EQUIPEMENT' |
  'DESCRIPTION' |
  'STATE';
module TicketSearchFilter {
  export const ALL: TicketSearchFilter = 'ALL';
  export const ID_TICKET: TicketSearchFilter = 'ID_TICKET';
  export const STATION_NAME: TicketSearchFilter = 'STATION_NAME';
  export const TYPE_PANNE: TicketSearchFilter = 'TYPE_PANNE';
  export const EQUIPEMENT: TicketSearchFilter = 'EQUIPEMENT';
  export const DESCRIPTION: TicketSearchFilter = 'DESCRIPTION';
  export const STATE: TicketSearchFilter = 'STATE';
  export function values(): TicketSearchFilter[] {
    return [
      ALL,
      ID_TICKET,
      STATION_NAME,
      TYPE_PANNE,
      EQUIPEMENT,
      DESCRIPTION,
      STATE
    ];
  }
}

export { TicketSearchFilter }