import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';

import { OktaRoutingModule } from './okta-routing.module';
import { oktaConfig } from './configuration/okta';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    OktaAuthModule,
    OktaRoutingModule,
  ],
  exports: [
  ],
  providers: [
    { provide: OKTA_CONFIG, useValue: oktaConfig }
  ]
})
export class OktaModule { }
