import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './component/table/table.component';
import { IconModule } from '../icon/icon.module';
import { SelectModule } from '../select/select.module';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safeHTML/safe-html.pipe';

@NgModule({
  declarations: [TableComponent, SafeHtmlPipe],
  imports: [
    TranslateModule,
    MatTooltipModule,
    CommonModule,
    IconModule,
    SelectModule,
    DpDatePickerModule,
    FormsModule,
  ],
  exports: [TableComponent]
})
export class TableModule { }
