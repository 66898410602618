/* tslint:disable */

/**
 * type of time period:
 *   * MORNING
 *   * AFTERNOON
 *   * NIGHT
 */
type PeriodType =
  'MORNING' |
  'AFTERNOON' |
  'NIGHT';
module PeriodType {
  export const MORNING: PeriodType = 'MORNING';
  export const AFTERNOON: PeriodType = 'AFTERNOON';
  export const NIGHT: PeriodType = 'NIGHT';
  export function values(): PeriodType[] {
    return [
      MORNING,
      AFTERNOON,
      NIGHT
    ];
  }
}

export { PeriodType }