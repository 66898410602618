import { BaseUser } from 'src/app/tinea-components/okta/models/base-user';
import { ApplicationAuthorization, ApplicationFeature, NetworkSummary, SubscriptionSummary, UserProfileSummary, UserType } from 'src/swagger-gen/web/models';
import { UserAllowedNetworkModel } from './UserAllowedNetworkModel';
import { UserAllowedStationModel } from './UserAllowedStationModel';
import { UserAllowedSubscriptionModel } from './UserAllowedSubscriptionModel';
import { UserAuthorizationModel } from './UserAuthorizationModel';

export class User extends BaseUser {
  
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  identityDelegation?: boolean;
  type: UserType;
  authorizations: UserAuthorizationModel[] = [];
  roles = []; // deprecated

  // @@ TODO A FAIRE AU PROPRE
  authorizedStations?: UserAllowedStationModel[];
  authorizedNetworks?: UserAllowedNetworkModel[];  
  authorizedSubscriptions?: UserAllowedSubscriptionModel[];

  constructor() {
    super();
  }

  initFromApi(userProfileSummary: UserProfileSummary): void {    
    this.uid = userProfileSummary.uid;
    this.email = userProfileSummary.email;
    this.firstName = userProfileSummary.firstName;
    this.lastName = userProfileSummary.lastName;
    this.identityDelegation = userProfileSummary.identityDelegation;
    this.type = userProfileSummary.type || UserType.OPERATOR;
    this.authorizedStations = UserAllowedStationModel.getListFromApi(userProfileSummary.authorizedStations);
    this.authorizedNetworks = UserAllowedNetworkModel.getListFromApi(userProfileSummary.authorizedNetworks);
    this.authorizedSubscriptions = UserAllowedSubscriptionModel.getListFromApi(userProfileSummary.authorizedSubscriptions);
    this.authorizations = UserAuthorizationModel.getListFromApi(userProfileSummary.authorizations);
  }

  initFromSession(fromSession: User): void {    
    if (fromSession) {
      this.uid = fromSession.uid;
      this.email = fromSession.email;
      this.firstName = fromSession.firstName;
      this.lastName = fromSession.lastName;
      this.identityDelegation = fromSession.identityDelegation;
      this.type = fromSession.type || UserType.OPERATOR;
      this.authorizations = fromSession.authorizations || [];
      this.authorizedStations = fromSession.authorizedStations || [];
      this.authorizedNetworks = fromSession.authorizedNetworks || [];
      this.authorizedSubscriptions = fromSession.authorizedSubscriptions || [];
    }
  }

  isAdmin(): boolean {
    return this.type === UserType.ADMIN;
  }

  hasAuthorization(): boolean {
    return this.authorizations.length > 0;
  }

  getApplicationAuthorizationById(val: ApplicationFeature): ApplicationAuthorization | null {
    return this.authorizations.find(a => a && a.feature === val) || null;
  }

  // -------------------------------------------------------------- //
  //                          has feature                           //
  // -------------------------------------------------------------- //
  hasFeatureVehicle(): boolean {
    const found = this.getApplicationAuthorizationById(ApplicationFeature.VEHICLE);
    return found ? true : false;
  }

  hasFeatureBadge(): boolean {
    const found = this.getApplicationAuthorizationById(ApplicationFeature.BADGE);
    return found ? true : false;
  }

  hasFeatureUser(): boolean {
    const found = this.getApplicationAuthorizationById(ApplicationFeature.USER);
    return found ? true : false;
  }

  hasFeatureSubscription(): boolean {
    const found = this.getApplicationAuthorizationById(ApplicationFeature.SUBSCRIPTION);
    return found ? true : false;
  }

  hasFeatureTicket(): boolean {
    const found = this.getApplicationAuthorizationById(ApplicationFeature.TICKET);
    return found ? true : false;
  }

  hasFeatureSiteManagement(): boolean {
    const found = this.getApplicationAuthorizationById(ApplicationFeature.SITE_MANAGEMENT);
    return found ? true : false;
  }

  hasFeatureSiteOperation(): boolean {
    const found = this.getApplicationAuthorizationById(ApplicationFeature.SITE_OPERATION);
    return found ? true : false;
  }

  hasFeatureTransaction(): boolean {
    const found = this.getApplicationAuthorizationById(ApplicationFeature.TRANSACTION);
    return found ? true : false;
  }

  // -------------------------------------------------------------- //
  //                        is readonly feature                     //
  // -------------------------------------------------------------- //
  isReadOnlyVehicle(): boolean {
    return this.getApplicationAuthorizationById(ApplicationFeature.VEHICLE).readOnly ? true : false;
  }

  isReadOnlyBadge(): boolean {
    return this.getApplicationAuthorizationById(ApplicationFeature.BADGE).readOnly ? true : false;
  }

  isReadOnlyUser(): boolean {
    return this.getApplicationAuthorizationById(ApplicationFeature.USER).readOnly ? true : false;
  }

  isReadOnlySubscription(): boolean {
    return this.getApplicationAuthorizationById(ApplicationFeature.SUBSCRIPTION).readOnly ? true : false;
  }

  isReadOnlyTransaction(): boolean {
    return this.getApplicationAuthorizationById(ApplicationFeature.TRANSACTION).readOnly ? true : false;
  }

  isReadOnlySiteManagement(): boolean {
    return this.getApplicationAuthorizationById(ApplicationFeature.SITE_MANAGEMENT).readOnly ? true : false;
  }

  isReadOnlySiteOperation(): boolean {
    return this.getApplicationAuthorizationById(ApplicationFeature.SITE_OPERATION).readOnly ? true : false;
  }
}
