import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {CubejsClient, CubejsClientModule} from '@cubejs-client/ngx';
import {TranslateModule} from '@ngx-translate/core';
import * as echarts from 'echarts';
import {ChartsModule} from 'ng2-charts';
import {DpDatePickerModule} from 'ng2-date-picker';
import {NgxEchartsModule} from 'ngx-echarts';
import {NgxSpinnerModule} from 'ngx-spinner';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCardModule} from '@angular/material/card';
import {environment} from 'src/environments/environment';
import {ReadWriteDirective} from '../directives/readWrite/read-write.directive';
import {PlainCubeComponent} from '../modules/dashboard/plain-cube/plain-cube.component';
import {QueryRendererComponent} from '../modules/dashboard/query-renderer/query-renderer.component';
import {EchartsRendererComponent} from '../modules/ems/component/echarts/echarts-renderer/echarts-renderer.component';
import {
  SubPlanAssociationsComponent
} from '../modules/subscriptions/sub-plan-associations/sub-plan-associations.component';
import {NumberFormatPipe} from '../pipes/number-format/NumberFormat.pipe';
import {PascalCasePipe} from '../pipes/strings/pascal-case.pipe';
import {CardComponent} from '../tinea-components/card/card.component';
import {DateTimePickerStaticModule} from '../tinea-components/dateTime-picker/dateTime-picker.module';
import {DisplayErrorsMessageModule} from '../tinea-components/display-errors-message/display-errors-message.module';
import {FileDownloadComponent} from '../tinea-components/file-download/file-download.component';
import {FileUploadComponent} from '../tinea-components/file-upload/file-upload.component';
import {LoadingModule} from '../tinea-components/loading/loading.module';
import {PagingModule} from '../tinea-components/paging/paging.module';
import {TableModule} from '../tinea-components/table/table.module';
import {TineaTableModule} from '../tinea-components/tinea-table/tinea-table.module';
import {AlarmsListComponent} from './components/alarms-list/alarms-list.component';
import {CollapseComponent} from './components/collapse/collapse.component';
import {
  ConfigurationStationGirComponent
} from './components/configuration-station-gir/configuration-station-gir.component';
import {
  ConfigurationStationLafonComponent
} from './components/configuration-station-lafon/configuration-station-lafon.component';
import {
  ConfigurationStationListComponent
} from './components/configuration-station/configuration-station-list/configuration-station-list.component';
import {
  ConfigurationStationComponent
} from './components/configuration-station/configuration-station/configuration-station.component';
import {DateRangeFilterComponent} from './components/date-range-filter/date-range-filter.component';
import {DetailStationComponent} from './components/detail-station/detail-station.component';
import {
  ModalDeleteStationComponent
} from './components/detail-station/modal-delete-station/modal-delete-station.component';
import {ErrorComponent} from './components/error/error.component';
import {FiltersTableComponent} from './components/filters-table/filters-table.component';
import {GnlListComponent} from './components/gnl-list/gnl-list.component';
import {FiltersGraphComponent} from './components/graph/filters-graph/filters-graph.component';
import {FiltersNGraphEchartComponent} from './components/graph/filters-n-graph-echart/filters-n-graph-echart.component';
import {GraphEchartComponent} from './components/graph/graph-echart/graph-echart.component';
import {SelectionTagComponent} from './components/graph/selection-tag/selection-tag.component';
import {HeaderComponent} from './components/header/header.component';
import {JumpingDotsComponent} from './components/jumping-dots/jumping-dots.component';
import {ModalBadgeAssignmentComponent} from './components/modal-badge-assignment/modal-badge-assignment.component';
import {ModalBadgeInfoComponent} from './components/modal-badge-info/modal-badge-info.component';
import {ModalBadgesUnassignComponent} from './components/modal-badges-unassign/modal-badges-unassign.component';
import {ModalBadgesComponent} from './components/modal-badges/modal-badges.component';
import {ModalColorTagGraphComponent} from './components/modal-color-tag-graph/modal-color-tag-graph.component';
import {ModalDeleteBadgeComponent} from './components/modal-delete-badge/modal-delete-badge.component';
import {
  ModalDeleteDiffusionListComponent
} from './components/modal-delete-diffsion-list/modal-delete-diffsion-list.component';
import {ModalDeleteRoleComponent} from './components/modal-delete-role/modal-delete-role.component';
import {
  ModalDeleteStationConfigComponent
} from './components/modal-delete-station-config/modal-delete-station-config.component';
import {
  ModalDeleteSubscriptionReportComponent
} from './components/modal-delete-subscription-report/modal-delete-subscription-report.component';
import {
  ModalDeleteSubscriptionComponent
} from './components/modal-delete-subscription/modal-delete-subscription.component';
import {ModalDeleteSvgComponent} from './components/modal-delete-svg/modal-delete-svg.component';
import {ModalDeleteUserComponent} from './components/modal-delete-user/modal-delete-user.component';
import {ModalDeleteVehicleComponent} from './components/modal-delete-vehicle/modal-delete-vehicle.component';
import {ModalDeviantTagsComponent} from './components/modal-deviant-tags/modal-deviant-tags.component';
import {ModalEditSubplanItemComponent} from './components/modal-edit-subplan-item/modal-edit-subplan-item.component';
import {
  ModalExportStationsTransactionsComponent
} from './components/modal-export-stations-transactions/modal-export-stations-transactions.component';
import {ModalNewExportComponent} from './components/modal-new-export/modal-new-export.component';
import {
  ModalOrganizeConfiguredGraphsComponent
} from './components/modal-organize-configured-graphs/modal-organize-configured-graphs.component';
import {
  ModalRemoveConfiguredGraphComponent
} from './components/modal-remove-configured-graph/modal-remove-configured-graph.component';
import {
  ModalStationAvailabiltyFormulaComponent
} from './components/modal-station-availabilty-formula/modal-station-availabilty-formula.component';
import {
  ModalSubscriptionAssignmentComponent
} from './components/modal-subscription-assignment/modal-subscription-assignment.component';
import {ModalSubscriptionInfoComponent} from './components/modal-subscription-info/modal-subscription-info.component';
import {
  ModalSubscriptionPlanDetailComponent
} from './components/modal-subscription-plan-detail/modal-subscription-plan-detail.component';
import {
  ModalUnitAcquisitionParametersComponent
} from './components/modal-unit-acquisition-parameters/modal-unit-acquisition-parameters.component';
import {ModalUsersComponent} from './components/modal-users/modal-users.component';
import {ModalVehiclesComponent} from './components/modal-vehicles/modal-vehicles.component';
import {AlertComponent} from './components/notifications/alert/alert.component';
import {SelectComponent} from './components/select/select.component';
import {SwitchGraphBarPieComponent} from './components/switch-graph-bar-pie/switch-graph-bar-pie.component';
import {TicketsTableComponent} from './components/tickets-table/tickets-table.component';
import {TogglePasswordDirective} from './directives/toggle-password.directive';
import {ModalStationsComponent} from './modal-stations/modal-stations.component';
import {
  ModalReferenceLineGraphComponent
} from './components/graph/modal-reference-line-graph/modal-reference-line-graph.component';
import {LogBookStatsComponent} from '../modules/reporting/logbook-stats/logbook-stats.component';
import {LogbookStatsTableComponent} from '../modules/reporting/logbook-stats-table/logbook-stats-table.component';
import {
  ModalExportRequestComponent
} from './components/graph/filters-graph/modal-export-request/modal-export-request.component';
import {FormatDatePipe} from "../pipes/dates/format-date.pipe";
import { LogBookStatsRefreshStateComponent } from '../modules/reporting/logbook-stats-refresh-state/logbook-stats-refresh-state.component';
import { IndicatorTableComponent } from '../modules/reporting/indicator-table/indicator-table.component';
import { AuthenCubeJsService } from '../tinea-components/okta/auth/services/authen-cubejs.service';
import { SelectMultipleComponent } from './components/select/multiple-select/select-multiple.component';
import { ToggleComponent } from './components/toggle/toggle.component';

const cubejsClientFactory = (authService: AuthenCubeJsService) => {
  return new CubejsClient(authService.config$);
};

@NgModule({
    declarations: [
        HeaderComponent,
        AlertComponent,
        DetailStationComponent,
        JumpingDotsComponent,
        ModalDeleteStationComponent,
        ModalDeleteSubscriptionComponent,
        ModalDeleteBadgeComponent,
        ModalDeleteUserComponent,
        ModalDeleteVehicleComponent,
        ErrorComponent,
        SelectionTagComponent,
        FiltersGraphComponent,
        GraphEchartComponent,
        ModalBadgeInfoComponent,
        ModalSubscriptionInfoComponent,
        ModalBadgeAssignmentComponent,
        ModalSubscriptionAssignmentComponent,
        ModalBadgesUnassignComponent,
        ConfigurationStationComponent,
        ConfigurationStationListComponent,
        FiltersTableComponent,
        FileDownloadComponent,
        FileUploadComponent,
        ModalStationsComponent,
        ModalBadgesComponent,
        ModalSubscriptionPlanDetailComponent,
        ModalUsersComponent,
        ModalVehiclesComponent,
        ModalDeleteStationConfigComponent,
        TogglePasswordDirective,
        QueryRendererComponent,
        ModalDeleteSvgComponent,
        ConfigurationStationGirComponent,
        ConfigurationStationLafonComponent,
        TicketsTableComponent,
        ModalEditSubplanItemComponent,
        SubPlanAssociationsComponent,
        PlainCubeComponent,
        ModalDeleteDiffusionListComponent,
        ModalDeleteSubscriptionReportComponent,
        ModalNewExportComponent,
        ModalExportStationsTransactionsComponent,
        CollapseComponent,
        AlarmsListComponent,
        GnlListComponent,
        ModalDeleteRoleComponent,
        ReadWriteDirective,
        FiltersNGraphEchartComponent,
        ModalStationAvailabiltyFormulaComponent,
        EchartsRendererComponent,
        NumberFormatPipe,
        PascalCasePipe,
        FormatDatePipe,
        SwitchGraphBarPieComponent,
        SelectComponent,
        SelectMultipleComponent,
        ToggleComponent,
        DateRangeFilterComponent,
        ModalUnitAcquisitionParametersComponent,
        ModalDeviantTagsComponent,
        CardComponent,
        ModalColorTagGraphComponent,
        ModalRemoveConfiguredGraphComponent,
        ModalOrganizeConfiguredGraphsComponent,
        ModalReferenceLineGraphComponent,
        LogBookStatsComponent,
        LogbookStatsTableComponent,
        ModalExportRequestComponent,
        LogBookStatsRefreshStateComponent,
        IndicatorTableComponent
    ],
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatTableModule,
        MatSlideToggleModule,
        MatChipsModule,
        RouterModule,
        LoadingModule,
        TranslateModule,
        FormsModule,
        MatDialogModule,
        ReactiveFormsModule,
        DragDropModule,
        DpDatePickerModule,
        NgxEchartsModule.forRoot({echarts}),
        DateTimePickerStaticModule,
        DisplayErrorsMessageModule,
        MatTooltipModule,
        TableModule,
        TineaTableModule,
        PagingModule,
        ChartsModule,
        NgxSpinnerModule,
        MatSelectModule,
        MatCardModule
    ],
    exports: [
        HeaderComponent,
        AlertComponent,
        DetailStationComponent,
        ConfigurationStationComponent,
        JumpingDotsComponent,
        ErrorComponent,
        SelectionTagComponent,
        FiltersGraphComponent,
        GraphEchartComponent,
        ModalBadgeInfoComponent,
        ModalSubscriptionInfoComponent,
        ConfigurationStationListComponent,
        FiltersTableComponent,
        FileDownloadComponent,
        FileUploadComponent,
        TogglePasswordDirective,
        QueryRendererComponent,
        TicketsTableComponent,
        SubPlanAssociationsComponent,
        PlainCubeComponent,
        CollapseComponent,
        AlarmsListComponent,
        GnlListComponent,
        ReadWriteDirective,
        FiltersNGraphEchartComponent,
        EchartsRendererComponent,
        NumberFormatPipe,
        PascalCasePipe,
        FormatDatePipe,
        SwitchGraphBarPieComponent,
        SelectComponent,
        SelectMultipleComponent,
        ToggleComponent,
        DateRangeFilterComponent,
        ModalUnitAcquisitionParametersComponent,
        ModalDeviantTagsComponent,
        TableModule,
        TranslateModule,
        CardComponent,
        LogBookStatsComponent,
        LogbookStatsTableComponent,
        LogBookStatsRefreshStateComponent,
        IndicatorTableComponent
    ],
    providers: [
      AuthenCubeJsService,
      {
        provide: CubejsClient,
        useFactory: cubejsClientFactory,
        deps: [AuthenCubeJsService],
      },
    ],
  
})
export class SharedModule {
}
