/* tslint:disable */

/**
 * The type of badge:
 *   * RFID
 *   * HID
 *   * LICENCE_PLATE
 *   * QR_CODE
 */
type BadgeType =
  'RFID' |
  'HID' |
  'LICENCE_PLATE' |
  'QR_CODE';
module BadgeType {
  export const RFID: BadgeType = 'RFID';
  export const HID: BadgeType = 'HID';
  export const LICENCE_PLATE: BadgeType = 'LICENCE_PLATE';
  export const QR_CODE: BadgeType = 'QR_CODE';
  export function values(): BadgeType[] {
    return [
      RFID,
      HID,
      LICENCE_PLATE,
      QR_CODE
    ];
  }
}

export { BadgeType }