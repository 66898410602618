/* tslint:disable */

/**
 * The connection state:
 *   * TO_CONFIGURE
 *   * CONFIGURED
 *   * CONNECTED
 *   * DISCONNECTED
 */
type ConnectionState =
  'TO_CONFIGURE' |
  'CONFIGURED' |
  'CONNECTED' |
  'DISCONNECTED';
module ConnectionState {
  export const TO_CONFIGURE: ConnectionState = 'TO_CONFIGURE';
  export const CONFIGURED: ConnectionState = 'CONFIGURED';
  export const CONNECTED: ConnectionState = 'CONNECTED';
  export const DISCONNECTED: ConnectionState = 'DISCONNECTED';
  export function values(): ConnectionState[] {
    return [
      TO_CONFIGURE,
      CONFIGURED,
      CONNECTED,
      DISCONNECTED
    ];
  }
}

export { ConnectionState }