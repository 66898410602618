export enum NumberRank {
  UNIT = 1,
  THOUSAND = 1000,
  MILLIONS = 1000000,
  BILLION = 1000000000
}

export function numberRankValues(): NumberRank[]{
  return [
    NumberRank.UNIT,
    NumberRank.THOUSAND,
    NumberRank.MILLIONS,
    NumberRank.BILLION
  ]
}
