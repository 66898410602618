/* tslint:disable */

/**
 * The synchronization state of the badge:
 *   * SYNCHRONIZED
 *   * TO_BE_SYNCHRONIZED
 */
type BadgeSynchroState =
  'SYNCHRONIZED' |
  'TO_BE_SYNCHRONIZED';
module BadgeSynchroState {
  export const SYNCHRONIZED: BadgeSynchroState = 'SYNCHRONIZED';
  export const TO_BE_SYNCHRONIZED: BadgeSynchroState = 'TO_BE_SYNCHRONIZED';
  export function values(): BadgeSynchroState[] {
    return [
      SYNCHRONIZED,
      TO_BE_SYNCHRONIZED
    ];
  }
}

export { BadgeSynchroState }