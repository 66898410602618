/* tslint:disable */

/**
 * field to filter for badge search:
 *   * ALL : All fields
 *   * TYPE
 *   * PROVIDER
 *   * VALUE
 */
type BadgeSearchFilter =
  'ALL' |
  'TYPE' |
  'PROVIDER' |
  'VALUE';
module BadgeSearchFilter {
  export const ALL: BadgeSearchFilter = 'ALL';
  export const TYPE: BadgeSearchFilter = 'TYPE';
  export const PROVIDER: BadgeSearchFilter = 'PROVIDER';
  export const VALUE: BadgeSearchFilter = 'VALUE';
  export function values(): BadgeSearchFilter[] {
    return [
      ALL,
      TYPE,
      PROVIDER,
      VALUE
    ];
  }
}

export { BadgeSearchFilter }