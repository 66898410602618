/* tslint:disable */

/**
 * The weather's state:
 *   * TODEFINE:
 *   * TODEFINE:
 */
type WeatherState =
  'TODEFINE' |
  'TODEFINE2' |
  'TODEFINE3';
module WeatherState {
  export const TODEFINE: WeatherState = 'TODEFINE';
  export const TODEFINE2: WeatherState = 'TODEFINE2';
  export const TODEFINE3: WeatherState = 'TODEFINE3';
  export function values(): WeatherState[] {
    return [
      TODEFINE,
      TODEFINE2,
      TODEFINE3
    ];
  }
}

export { WeatherState }