/* tslint:disable */

/**
 * field to filter for diffusion list search:
 *   * NAME : the diffusion list's name
 *   * TYPE : the diffusion list's type
 */
type DiffusionListSearchFilter =
  'NAME' |
  'TYPE';
module DiffusionListSearchFilter {
  export const NAME: DiffusionListSearchFilter = 'NAME';
  export const TYPE: DiffusionListSearchFilter = 'TYPE';
  export function values(): DiffusionListSearchFilter[] {
    return [
      NAME,
      TYPE
    ];
  }
}

export { DiffusionListSearchFilter }