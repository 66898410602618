/* tslint:disable */

/**
 * field to filter for ticket search:
 *   * OPENED
 *   * IN_PROGRESS
 *   * FROZEN
 *   * RESOLVED
 *   * CLOSED
 *   * CANCELED
 *   * RE_OPENED
 */
type TicketState =
  'OPENED' |
  'IN_PROGRESS' |
  'FROZEN' |
  'RESOLVED' |
  'CLOSED' |
  'CANCELED' |
  'RE_OPENED';
module TicketState {
  export const OPENED: TicketState = 'OPENED';
  export const IN_PROGRESS: TicketState = 'IN_PROGRESS';
  export const FROZEN: TicketState = 'FROZEN';
  export const RESOLVED: TicketState = 'RESOLVED';
  export const CLOSED: TicketState = 'CLOSED';
  export const CANCELED: TicketState = 'CANCELED';
  export const RE_OPENED: TicketState = 'RE_OPENED';
  export function values(): TicketState[] {
    return [
      OPENED,
      IN_PROGRESS,
      FROZEN,
      RESOLVED,
      CLOSED,
      CANCELED,
      RE_OPENED
    ];
  }
}

export { TicketState }