export enum CoreEnum {
  // ==========================
  //            image
  // ==========================
  // sort
  ICON_SORT_DESC = 'icon-sort-desc',
  ICON_SORT_ASC = 'icon-sort-asc',
  // path
  IMG_PATH_SORT_ASC = 'assets/icons/sorted.svg',
  IMG_PATH_SORT_DESC = 'assets/icons/sorted.svg',
  IMG_PATH_SORT_NONE = 'assets/icons/no-sort.svg',
  IMG_PATH_SENSITIVE_CLIENT = 'assets/icons/sensitive.client.svg',
  IMG_PATH_CONTROL = 'assets/icons/control.svg',
  IMG_PATH_INFO = 'assets/icons/info.svg',
  IMG_PATH_LOCK = 'assets/icons/lock.svg',
  IMG_PATH_WARNING = 'assets/icons/warning.svg',
  IMG_PATH_UP_DOWN_ARROW = 'assets/icons/up-down-arrow.svg',
  IMG_PATH_NOTE_EMPTY = 'assets/icons/comment-add.svg',
  IMG_PATH_NOTE_NOT_EMPTY = 'assets/icons/comment-fill.svg',
  IMG_PATH_UP = 'assets/icons/up.svg',
  IMG_PATH_DOWN = 'assets/icons/down.svg',
  IMG_PATH_EDIT = 'assets/icons/edit.svg',
  IMG_PATH_PRESET_EDIT = 'assets/icons/settings.svg',
  IMG_PATH_VALIDATE = 'assets/icons/done.svg',
  IMG_PATH_REMOVE = 'assets/icons/remove.svg',
  IMG_PATH_CANCEL = 'assets/icons/close.svg',
  IMG_PATH_SPLIT = 'assets/icons/cut.svg',
  IMG_PATH_SPLIT_ADD = 'assets/icons/btn-add.svg',
  IMG_PATH_SPINNER = 'assets/icons/spinner.svg',
  // css
  CLASS_OPERATION_TYPE_VER = 'bk-operation-VER',
  CLASS_OPERATION_TYPE_REF = 'bk-operation-REF',
  CLASS_OPERATION_TYPE_LMX = 'bk-operation-LMX',
  CLASS_OPERATION_TYPE_ACTIVE = 'bk-operation-active',
  CLASS_COIL_STATE_COMPLETED = 'bk-coil-state-COMPLETED',
  CLASS_COIL_STATE_PROGRAMMED = 'bk-coil-state-PROGRAMMED',
  CLASS_COIL_STATE_IN_PROGRESS = 'bk-coil-state-IN_PROGRESS',
  CLASS_COIL_STATE_IDENTIFIED = 'bk-coil-state-IDENTIFIED',
  CLASS_STOP_STATE_CREATED = 'bk-stop-state-CREATED',
  CLASS_STOP_STATE_IN_PROGRESS = 'bk-stop-state-IN_PROGRESS',
  CLASS_STOP_STATE_COMPLETED = 'bk-stop-state-COMPLETED',
  CLASS_BTN_FULLSCREEN = 'btn-fullscreen',
  CLASS_BTN_IMG = 'btn-img',
  CLASS_SPINNER_FOR_ROTATE = 'rotate-spinner',
  CLASS_MODAL = 'modal',
  CLASS_MODAL_NO_CLOSE_OUTSIDE = 'modal-no-close-outside',
  CLASS_NO_WIDTH = 'no-width',
  // text
  TEXT_YES = 'oui',
  TEXT_NO = 'non',
  TEXT_NA = 'N/A',
  TEXT_CHOICE = 'Choisir',
  // HEADERS
  HEADER_ROLE = 'role',

  // LOCAL STORAGE
  STATION_DETAIL = 'station-detail',
  ENDPOINT_ELECTRICITY = 'electrical',

  // STATION H20 URL OPC UA URL :
  OPCUA_URL_PREFIX = 'opc.tcp://',
  OPCUA_ACQUISITION_LOST_DELAY_DEFAULT = '900',

  // MAX SELECTED UNIT FOR TAGs
  MAX_UNITS = 5,

  // BADGES, SUBSCRIPTIONS
  DETAIL_BADGE = 'DETAIL_BADGE',
  DETAIL_SUBSCRIPTION = 'DETAIL_SUBSCRIPTION',
  ASSIGN_BADGE = 'ASSIGN_BADGE',
  UNASSIGN_BADGE = 'UNASSIGN_BADGE',
  ASSIGN_SUBSCRIPTION = 'ASSIGN_SUBSCRIPTION',
  USER_SUBSCRIPTION = 'USER_SUBSCRIPTION',
  VEHICLE_SUBSCRIPTION = 'VEHICLE_SUBSCRIPTION',

  // SEARCH MATCHING TYPE
  DEFAULT_MATCHING_TYPE = 'PARTIAL_MATCH_IGNORE_CASE'
}
