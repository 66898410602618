/* tslint:disable */

/**
 * field to filter for subscriptions search:
 *   * ALL : All fields
 *   * NAME : the subscription's name
 */
type SubscriptionSearchFilter =
  'ALL' |
  'NAME';
module SubscriptionSearchFilter {
  export const ALL: SubscriptionSearchFilter = 'ALL';
  export const NAME: SubscriptionSearchFilter = 'NAME';
  export function values(): SubscriptionSearchFilter[] {
    return [
      ALL,
      NAME
    ];
  }
}

export { SubscriptionSearchFilter }