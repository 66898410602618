/* tslint:disable */

/**
 * The user types:
 *   * ADMIN : an administrator
 *   * OPERATOR : an operator
 *   * PUBLIC : a public user
 */
type UserType =
  'ADMIN' |
  'OPERATOR' |
  'PUBLIC';
module UserType {
  export const ADMIN: UserType = 'ADMIN';
  export const OPERATOR: UserType = 'OPERATOR';
  export const PUBLIC: UserType = 'PUBLIC';
  export function values(): UserType[] {
    return [
      ADMIN,
      OPERATOR,
      PUBLIC
    ];
  }
}

export { UserType }