/* tslint:disable */

/**
 * field to filter for user search:
 *   * ALL : All fields
 *   * BADGE : Badge informations
 *   * SUBSCRIPTION : subscription name
 *   * FIRST_NAME :
 *   * LAST_NAME :
 *   * EMAIL :
 */
type UserSearchFilter =
  'ALL' |
  'FIRST_NAME' |
  'LAST_NAME' |
  'EMAIL' |
  'BADGE' |
  'SUBSCRIPTION';
module UserSearchFilter {
  export const ALL: UserSearchFilter = 'ALL';
  export const FIRST_NAME: UserSearchFilter = 'FIRST_NAME';
  export const LAST_NAME: UserSearchFilter = 'LAST_NAME';
  export const EMAIL: UserSearchFilter = 'EMAIL';
  export const BADGE: UserSearchFilter = 'BADGE';
  export const SUBSCRIPTION: UserSearchFilter = 'SUBSCRIPTION';
  export function values(): UserSearchFilter[] {
    return [
      ALL,
      FIRST_NAME,
      LAST_NAME,
      EMAIL,
      BADGE,
      SUBSCRIPTION
    ];
  }
}

export { UserSearchFilter }