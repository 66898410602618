<section id="okta-custom-wrap">
    <div class="okta-custom-wrap-container">
        <div *ngIf="isLoading; else result">
        </div>

        <ng-template #result>
            <div *ngIf="isAuthenticated; else noAuthenticated">
                <!-- display nothing -->
                IS AUTHENTICATED
            </div>

            <ng-template #noAuthenticated>
                <div>
                    <div class="okta-custom-message text-center py-20">
                        {{'login.errors.message' | translate}}
                    </div>
                    <div class="text-center">
                        <button class="btn btn--primary mr-10" (click)="retry()">{{'login.errors.retry' | translate}}</button>
                        <!-- <button class="btn btn--outline-secondary"
                            (click)="reconnect()">{{'login.errors.resign_in' | translate}}</button> -->
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</section>
