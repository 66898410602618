/* tslint:disable */

/**
 * The types of connection:
 *   * OPCUA
 *   * LAFON
 *   * GIR
 */
type ConnectionType =
  'OPCUA' |
  'LAFON' |
  'GIR';
module ConnectionType {
  export const OPCUA: ConnectionType = 'OPCUA';
  export const LAFON: ConnectionType = 'LAFON';
  export const GIR: ConnectionType = 'GIR';
  export function values(): ConnectionType[] {
    return [
      OPCUA,
      LAFON,
      GIR
    ];
  }
}

export { ConnectionType }