/* tslint:disable */

/**
 * The alarm state:
 *   * AUTOMATIC: automatic acknoledgement
 *   * MANUAL: manual acknoledgement
 */
type AcknowledgementOrigin =
  'AUTOMATIC' |
  'MANUAL';
module AcknowledgementOrigin {
  export const AUTOMATIC: AcknowledgementOrigin = 'AUTOMATIC';
  export const MANUAL: AcknowledgementOrigin = 'MANUAL';
  export function values(): AcknowledgementOrigin[] {
    return [
      AUTOMATIC,
      MANUAL
    ];
  }
}

export { AcknowledgementOrigin }