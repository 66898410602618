/* tslint:disable */

/**
 * type of diffusion:
 *   * EMAIL
 *   * SMS
 *   * VOICE_CALL
 */
type DiffusionType =
  'EMAIL' |
  'SMS' |
  'VOICE_CALL';
module DiffusionType {
  export const EMAIL: DiffusionType = 'EMAIL';
  export const SMS: DiffusionType = 'SMS';
  export const VOICE_CALL: DiffusionType = 'VOICE_CALL';
  export function values(): DiffusionType[] {
    return [
      EMAIL,
      SMS,
      VOICE_CALL
    ];
  }
}

export { DiffusionType }