/* tslint:disable */

/**
 * The type of agent:
 *   * H2OPCUA
 */
type AgentType =
  'H2OPCUA';
module AgentType {
  export const H2OPCUA: AgentType = 'H2OPCUA';
  export function values(): AgentType[] {
    return [
      H2OPCUA
    ];
  }
}

export { AgentType }