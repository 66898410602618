import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { retry, tap, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { environment } from 'src/environments/environment';
import { AlarmRealTimeList, AlarmSearchBody } from 'src/swagger-gen/web/models';
import { Alarm } from 'src/app/shared/models/alarm/alarm'; // todo: get from swagger
import { BaseService } from '../services/base.service';

@Injectable({
  providedIn: 'root',
})
export class AlarmRepo extends BaseService {
  baseUrl = environment.apiUrl;
  stationUrl = this.baseUrl + '/stations/';
  alarmsUrl = this.baseUrl + '/alarms/';

  /** Alarms count */
  private count = new BehaviorSubject(0);
  readonly currentCount$ = this.count.asObservable();

  /** Current alarms list */
  private alarms = new BehaviorSubject([]);
  readonly currentAlarmsList$ = this.alarms.asObservable();

  readonly DEFAULT_ALARMS_LIMIT = '200';

  constructor(private logger: NGXLogger, private httpClient: HttpClient) {
    super();
  }

  /**
   * Get all active alarms
   * @param limit number
   * @param offset number
   * @returns Observable result
   */
   getActiveAlarms(
    params: any[],
    body: AlarmSearchBody,
    limit?: number,
    offset?: number,
  ): Observable<AlarmRealTimeList> {
    const searchAlarmsUrl = this.alarmsUrl + 'search';
    const paging = this.pagination(offset, limit, this.DEFAULT_ALARMS_LIMIT);

    let requestString: string;
    requestString = paging;
    let queryParams = '';
    params.forEach((p: any) => {
      queryParams += '&' + p.key + '=' + p.value;
    });
    requestString += queryParams;
    const requestParams = new HttpParams({ fromString: requestString });
    const httpOptions = {
      headers: this.httpHeaders(),
      params: requestParams,
    };

    return this.httpClient
      .post<AlarmRealTimeList>(searchAlarmsUrl, body, httpOptions)
      .pipe(
        tap((alarms: AlarmRealTimeList) => {
          this.logger.log(`Got ${alarms.paging.totalItems} alarms in service.`);
        }), // DEBUG
        catchError(this.handleServerError)
      );
  }

  /**
   * Get all active alarms of an station
   * @param eUid station uid
   * @param limit number
   * @param offset number
   * @returns Observable result
   */
  getStationAlarms(
    eUid: string,
    params: any[],
    body: AlarmSearchBody,
    limit?: number,
    offset?: number
  ): Observable<AlarmRealTimeList> {
    const siteAlarmsUrl = this.stationUrl + eUid + '/alarms/active';
    const paging = this.pagination(offset, limit, this.DEFAULT_ALARMS_LIMIT);

    let requestString: string;
    requestString = paging;
    let queryParams = '';
    if(params) {
      params.forEach((p: any) => {
        queryParams += '&' + p.key + '=' + p.value;
      });
    }
    requestString += queryParams;
    const requestParams = new HttpParams({ fromString: requestString });
    const httpOptions = {
      headers: this.httpHeaders(),
      params: requestParams,
    };

    return this.httpClient
      .post<AlarmRealTimeList>(siteAlarmsUrl, body, httpOptions)
      .pipe(
        tap((alarms: AlarmRealTimeList) => {
          this.logger.log(`Got ${alarms.paging.totalItems} alarms in service.`);
        }), // DEBUG
        catchError(this.handleServerError)
      );
  }

  /**
   * Acknowledge one alarm of an station.
   * @param eUid station uid
   * @param alarmUid alarm to acknowledge uid
   * @returns Observable result
   * */
  acknowledgeAlarm(eUid: string, alarmUid: string): Observable<any> {
    const alarmAcknowledgementUrl = this.stationUrl + eUid + '/alarms/' + alarmUid + '/ack';
    const httpOptions = {
      headers: this.httpHeaders(),
    };
    this.logger.trace('alarmUID in acknowledgeAlarm', alarmUid);
    return this.httpClient
      .post<any>(alarmAcknowledgementUrl, httpOptions)
      .pipe(catchError(this.handleServerError));
  }

  /**
   * Restore a previously acknowledged alarm of an station.
   * @param eUid station uid
   * @param alarmUid alarm to restore uid
   * @returns Observable result
   */
  restoreAlarm(eUid: string, alarmUid: string): Observable<any> {
    const alarmAcknowledgementUrl = this.stationUrl + eUid + '/alarms/' + alarmUid + '/un-ack';
    const httpOptions = {
      headers: this.httpHeaders(),
    };
    this.logger.trace('alarmUID in restoreAlarm', alarmUid);
    return this.httpClient
      .post<any>(alarmAcknowledgementUrl, httpOptions)
      .pipe(catchError(this.handleServerError));
  }

  /**
   * Acknowledge all alarms of an station
   * @param eUid station uid
   * @returns Observable result
   */
  acknowledgeAllAlarms(eUid: string): Observable<any> {
    const alarmsAcknowledgementUrl = this.stationUrl + eUid + '/alarms/ack';
    const httpOptions = {
      headers: this.httpHeaders(),
    };
    return this.httpClient
      .post<any>(alarmsAcknowledgementUrl, httpOptions)
      .pipe(catchError(this.handleServerError));
  }

  resetAlarms(code: number): Observable<any> {
    if (code === 1234) {
      return of(`Reseted alarms`);
    } else {
      return throwError('Wrong code');
    }
  }

  exportAlarms(params: any[]): Observable<any> {
    let queryParams = '';
    params.forEach((p: any, i: number) => {
      queryParams += `${i > 0 ? '&' : ''}` + p.key + '=' + p.value;
    });

    const url = this.alarmsUrl + 'export';
    const requestParams = new HttpParams({ fromString: queryParams });

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const httpOptions: any = {
      headers: headers,
      params: requestParams,
      responseType: 'blob' as 'json',
      observe: 'response',
    };

    return this.httpClient.post<any>(url,{}, httpOptions).pipe(
      tap((data) => {
        this.logger.trace(`Download all alarms:`, data);
      }), // DEBUG
      catchError(this.handleServerError)
    );
  }

  exportAlarmsByStation(uid: string, params: any[]): Observable<any> {
    let queryParams = '';
    params.forEach((p: any, i: number) => {
      queryParams += `${i > 0 ? '&' : ''}` + p.key + '=' + p.value;
    });

    //TODO
    const url = this.stationUrl + uid + '/alarms/active/export';
    const requestParams = new HttpParams({ fromString: queryParams });

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const httpOptions: any = {
      headers: headers,
      params: requestParams,
      responseType: 'blob' as 'json',
      observe: 'response',
    };

    return this.httpClient.post<any>(url, {}, httpOptions).pipe(
      tap((data) => {
        this.logger.trace(`Download all alarms:`, data);
      }), // DEBUG
      catchError(this.handleServerError)
    );
  }
}
