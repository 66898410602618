import { ContentId } from "src/app/enums/env/content-id.enum";
import { ContentEnv } from "src/app/interfaces/env/ContentEnv";

export const dashboardEnvironment: ContentEnv = {

    //collapse sections
    sectionWhiteList: [
        {
            id: ContentId.PRECONFIGURED_SECTION,
            collapse: false
        },
        {
            id: ContentId.ALARM_SECTION,
            collapse: false
        }
    ],


    //graphs
    graphWhiteList: [
        ContentId.PRECONFIGURED_GRAPH,
        ContentId.ALARM_GRAPH,
        ContentId.ALARM_TOGGLE_GRAPH
    ]
}