import { Injectable } from '@angular/core';
import { UserSessionService } from 'src/app/tinea-components/okta/services/session/user-session.service';

@Injectable({providedIn: 'root'})
export class TokenExpirationService {

  constructor(private userSessionService: UserSessionService) { }
  /**
   * Check if the token is expired
   */
  authTokenExpired(): boolean {
    const expireAt = this.userSessionService.getAuthExpireAt();
    const dateNow = Math.ceil((Date.now() / 1000));

    const isExpired = dateNow > expireAt;
    return isExpired;
  }
}
